<template>
  <v-card>
    <v-toolbar dark color="primary">
      <v-btn icon dark @click="close()">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>My Exam</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn dark text @click="close()">
          cancel
        </v-btn>
        <v-btn dark text @click="save()">
          Save
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-row class="pa-4">
      <v-col cols="12" md="6" xm="12">
        <v-data-table
          v-model="selected"
          :headers="headers"
          :items="examComponents"
          :single-select="singleSelect"
          item-key="name"
          show-select
          dense
          :height="$mq === 'mobile' ? '350px' : '100%'"
          class="elevation-1"
        >
          <template v-slot:item.types="{ item }">
            <v-icon v-if="item.type == 'Audio'" color="black">volume_up</v-icon>
            <v-icon v-else color="black">ondemand_video</v-icon>
          </template>
          <template v-slot:top> </template>
        </v-data-table>
      </v-col>
      <v-col cols="12" md="6" xm="12">
        <v-simple-table
          dense
          fixed-header
          :height="$mq === 'mobile' ? '350px' : '100%'"
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  Type
                </th>
                <th class="text-left">
                  Name
                </th>
                <th class="text-left">
                  Abbrev
                </th>
                <th class="text-left">
                  Order
                </th>
              </tr>
            </thead>

            <draggable v-model="components" tag="tbody">
              <tr v-for="item in components" :key="item.id">
                <td>
                  <v-icon v-if="item.type == 'Audio'" color="black"
                    >volume_up</v-icon
                  >
                  <v-icon v-else color="black">ondemand_video</v-icon>
                </td>
                <td>{{ item.name }}</td>
                <td>{{ item.abbrev }}</td>

                <td scope="row">
                  <v-icon color="black">menu</v-icon>
                </td>
              </tr>
            </draggable>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import * as fb from '@/firebase'
import draggable from 'vuedraggable'
export default {
  name: 'ManageExamDialog',
  props: ['examComponents', 'currentUserId'],
  components: {
    draggable
  },
  data() {
    return {
      dragging: false,
      singleSelect: false,
      selected: [],
      components: [],
      headers: [
        {
          text: 'Type',
          align: 'start',
          sortable: false,
          value: 'types'
        },
        { text: 'Name', value: 'name' },
        { text: 'Abrreviation', value: 'abbrev' },
        { text: 'Description', value: 'description' }
      ]
    }
  },
  watch: {
    selected: function() {
      this.components = []

      for (const item of this.selected) {
        this.components.push({ ...item })
      }
    }
  },
  async mounted() {
    const components = await fb.db
      .collection('Proxies')
      .doc(this.currentUserId)
      .collection('Exam')
      .get()

    if (components.docs.length > 0) {
      for (const item of components.docs) {
        this.selected.push({ id: item.id, ...item.data() })
        this.components.push({ id: item.id, ...item.data() })
      }

      var absentItems = this.components.filter(e => {
        return !this.examComponents.some(x => {
          return e.name == x.name
        })
      })

      for (const item of absentItems) {
        this.examComponents.push(item)
      }

      this.selected.sort((a, b) => (a.order > b.order ? 1 : -1))
      this.components.sort((a, b) => (a.order > b.order ? 1 : -1))
    }
  },
  methods: {
    async save() {
      this.$emit('closeManage')
      try {
        await fb.proxiesCollection
          .doc(this.currentUserId)
          .collection('Exam')
          .get()
          .then(async snapshot => {
            for (const item of snapshot.docs) {
              await fb.proxiesCollection
                .doc(this.currentUserId)
                .collection('Exam')
                .doc(item.id)
                .collection('Files')
                .get()
                .then(async snap => {
                  for (const file of snap.docs) {
                    await fb.proxiesCollection
                      .doc(this.currentUserId)
                      .collection('Files')
                      .doc(file.id)
                      .delete()
                  }
                })
                .then(async () => {
                  await fb.proxiesCollection
                    .doc(this.currentUserId)
                    .collection('Exam')
                    .doc(item.id)
                    .delete()
                })
            }
          })
          .then(async () => {
            let id = 0
            for (const item of this.components) {
              await fb.proxiesCollection
                .doc(this.currentUserId)
                .collection('Exam')
                .doc(id.toString())
                .set({
                  ...item,
                  order: id
                })
              id++
            }
          })
      } catch (error) {
        console.log(error)
      }
    },
    close() {
      this.$emit('closeManage')
    }
  }
}
</script>
