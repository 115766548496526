<template>
  <v-card>
    <v-card-title class="d-flex justify-space-between">
      Update Event
    </v-card-title>
    <v-card-text>
      <v-form v-model="valid">
        <v-container>
          <v-row>
            <v-col cols="12" md="12">
              <v-text-field
                v-model="selectedEvent.name"
                :rules="[rules.required]"
                label="Name"
                rounded
                outlined
                dense
                hide-details="hint"
                required
              ></v-text-field>
            </v-col>
            <v-dialog
              ref="dialog"
              v-model="dialog"
              :return-value.sync="date"
              persistent
              width="290px"
            >
              <v-date-picker v-model="date" scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="dialog = false">
                  Cancel
                </v-btn>
                <v-btn text color="primary" @click="$refs.dialog.save(date)">
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>

            <v-col cols="12" md="12">
              <v-text-field
                :disabled="editOptions !== '0'"
                v-model="computedDateFormattedMomentjs"
                :value="computedDateFormattedMomentjs"
                append-icon="event"
                label="Date"
                dense
                outlined
                rounded
                hide-details="hint"
                :rules="[rules.required]"
                @click:append="dialog = true"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-select
                v-model="startTime"
                :items="times"
                :rules="[rules.required, rules.validTime]"
                :value="startTime"
                label="Start Time"
                rounded
                outlined
                dense
                required
              ></v-select>
            </v-col>

            <v-col cols="12" md="6">
              <v-select
                v-model="endTime"
                :items="times"
                :rules="[rules.required, rules.validTime]"
                :value="endTime"
                label="End Time"
                rounded
                outlined
                dense
                required
              ></v-select>
            </v-col>
            <v-col cols="12" md="12">
              <v-radio-group v-model="editOptions" mandatory>
                <v-radio
                  label="Update this event only"
                  color="green"
                  value="0"
                ></v-radio>
                <v-radio
                  label="Update this and future events"
                  color="primary"
                  value="1"
                ></v-radio>
                <v-radio
                  label="Update this and all related events"
                  color="red"
                  value="2"
                ></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn @click.stop="cancel" color="red" text>Cancel</v-btn>
      <v-btn @click.stop="editType" color="primary" text>Update</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import * as fb from '../../firebase'
import * as moment from 'moment'
export default {
  name: 'EditEventDialog',
  props: ['selectedEvent'],
  data() {
    return {
      date: new Date().toISOString().substr(0, 10),
      dialog: false,
      valid: false,
      repeat: true,
      name: '',
      startTime: '',
      editOptions: '',
      endTime: '',
      event: {},
      rules: {
        required: value => !!value || 'Required',
        validTime: () => {
          const start = moment(this.startTime, ['hh:mm A', 'hh:mm'])
          const end = moment(this.endTime, ['hh:mm A', 'hh:mm'])
          return start.isBefore(end) || 'Start time must be before end time'
        }
      }
    }
  },
  watch: {
    selectedEvent: function() {
      this.name = this.selectedEvent.name
      this.date = this.selectedEvent.date.toISOString().substr(0, 10)

      this.startTime = this.times.find(t => t === this.startTimeNow)
      this.endTime = this.times.find(t => t === this.endTimeNow)
    }
  },
  mounted() {
    this.name = this.selectedEvent.name
    this.date = this.selectedEvent.date.toISOString().substr(0, 10)

    this.startTime = this.times.find(t => t === this.startTimeNow)
    this.endTime = this.times.find(t => t === this.endTimeNow)
  },
  computed: {
    startTimeNow: function() {
      return moment(this.selectedEvent.startTime.toDate()).format('hh:mm A')
    },
    endTimeNow: function() {
      return moment(this.selectedEvent.endTime.toDate()).format('hh:mm A')
    },
    computedDateFormattedMomentjs: {
      get() {
        return this.date ? moment(this.date).format('dddd, MMMM Do YYYY') : ''
      },
      set() {}
    },
    times: function() {
      const times = []
      const timesLimitted = []

      const secondsInDay = 86400
      const steps = 900 // 15 minutes
      for (let i = 0; i < secondsInDay; i += steps) {
        const d = new Date()
        d.setHours(0)
        d.setMinutes(0)
        d.setSeconds(i)

        const time = moment(
          fb.db.app.firebase_.firestore.Timestamp.fromDate(d).toDate()
        ).format('hh:mm A')

        times.push(time)
      }

      const timesStart = times.indexOf('07:00 AM')
      let index = timesStart

      while (index !== times.length) {
        timesLimitted.push(times[index])
        index++
      }

      return timesLimitted
    }
  },
  methods: {
    cancel() {
      this.$emit('closeEdit')
    },
    editType() {
      if (this.editOptions === '0') {
        this.editEvent()
      } else if (this.editOptions === '1') {
        this.editNowAndFuture()
      } else if (this.editOptions === '2') {
        this.editAllEvents()
      }
    },
    async editEvent() {
      const start = fb.db.app.firebase_.firestore.Timestamp.fromDate(
        new Date(`${this.date.split('-').join('/')} ${this.startTime}`)
      )
      const end = fb.db.app.firebase_.firestore.Timestamp.fromDate(
        new Date(`${this.date.split('-').join('/')} ${this.endTime}`)
      )
      const day = moment(start.toDate()).format('dddd')

      try {
        await fb.proxiesCollection
          .doc(fb.auth.currentUser.uid)
          .collection('OfficeHours')
          .doc(this.selectedEvent.id)
          .set(
            {
              name: this.name,
              startTime: start,
              endTime: end,
              day: day,
              repeat: this.repeat
            },
            { merge: true }
          )
        this.$emit('closeEdit')
      } catch (error) {
        this.error = error.message
        console.log(error)
      }
    },
    editNowAndFuture() {
      try {
        fb.proxiesCollection
          .doc(fb.auth.currentUser.uid)
          .collection('OfficeHours')
          .where('relatedKey', '==', this.selectedEvent.relatedKey)
          .get()
          .then(snapshot => {
            {
              for (const item of snapshot.docs) {
                const startDate = item
                  .data()
                  .startTime.toDate()
                  .toISOString()
                  .substr(0, 10)

                const endDate = item
                  .data()
                  .endTime.toDate()
                  .toISOString()
                  .substr(0, 10)

                const start = fb.db.app.firebase_.firestore.Timestamp.fromDate(
                  new Date(
                    `${startDate.split('-').join('/')} ${this.startTime}`
                  )
                )
                const end = fb.db.app.firebase_.firestore.Timestamp.fromDate(
                  new Date(`${endDate.split('-').join('/')} ${this.endTime}`)
                )

                const endTime = moment(item.data().endTime.toDate())
                const selectedInterval = moment(
                  this.selectedEvent.endTime.toDate()
                )

                if (endTime.isSameOrAfter(selectedInterval, 'day')) {
                  // console.log({
                  //   name: this.name,
                  //   startTime: start,
                  //   endTime: end
                  // })
                  fb.proxiesCollection
                    .doc(fb.auth.currentUser.uid)
                    .collection('OfficeHours')
                    .doc(item.id)
                    .set(
                      {
                        name: this.name,
                        startTime: start,
                        endTime: end
                      },
                      { merge: true }
                    )
                }
              }
            }
          })
        this.$emit('closeEdit')
      } catch (error) {
        this.error = error.message
        console.log(error)
      }
    },
    editAllEvents() {
      try {
        fb.proxiesCollection
          .doc(fb.auth.currentUser.uid)
          .collection('OfficeHours')
          .where('relatedKey', '==', this.selectedEvent.relatedKey)
          .get()
          .then(async snapshot => {
            {
              for (const item of snapshot.docs) {
                const startDate = item
                  .data()
                  .startTime.toDate()
                  .toISOString()
                  .substr(0, 10)

                const endDate = item
                  .data()
                  .endTime.toDate()
                  .toISOString()
                  .substr(0, 10)

                const start = fb.db.app.firebase_.firestore.Timestamp.fromDate(
                  new Date(
                    `${startDate.split('-').join('/')} ${this.startTime}`
                  )
                )
                const end = fb.db.app.firebase_.firestore.Timestamp.fromDate(
                  new Date(`${endDate.split('-').join('/')} ${this.endTime}`)
                )

                await fb.proxiesCollection
                  .doc(fb.auth.currentUser.uid)
                  .collection('OfficeHours')
                  .doc(item.id)
                  .set(
                    {
                      name: this.name,
                      startTime: start,
                      endTime: end
                    },
                    { merge: true }
                  )
              }
            }
          })
        this.$emit('closeEdit')
      } catch (error) {
        this.error = error.message
        console.log(error)
      }
    }
  }
}
</script>
