<template>
  <v-card>
    <v-toolbar elevation="0">
      <v-toolbar-title class="d-flex justify-space-between">
        Add Hours
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn @click.stop="addEvent" color="primary" text>Add</v-btn>
      <v-btn @click="close" color="red" text>Cancel</v-btn>
    </v-toolbar>
    <v-row>
      <v-col class="ml-2 mr-2">
        <v-alert v-if="eventMessage" width="100%" dismissible type="error">{{
          eventMessage
        }}</v-alert>
      </v-col>
    </v-row>

    <v-card-text>
      <v-form v-model="valid">
        <v-container>
          <v-row>
            <v-col cols="12" md="12">
              <div>Select Type</div>
              <v-radio-group v-model="type" mandatory row>
                <v-radio
                  label="Routine Appointment Hours"
                  color="green"
                  value="OperatingHours"
                ></v-radio>
                <v-radio
                  label="Immediate Waiting Room Hours"
                  color="primary"
                  value="OfficeHours"
                ></v-radio>
                <v-radio label="Other" color="red" value="Other"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="12">
              <v-text-field
                v-model="name"
                :rules="[rules.required]"
                label="Name"
                rounded
                outlined
                dense
                hide-details="hint"
                required
              ></v-text-field>
            </v-col>
            <v-dialog
              ref="dialog"
              v-model="dialog"
              :return-value.sync="date"
              persistent
              width="290px"
            >
              <v-date-picker v-model="date" scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="dialog = false">
                  Cancel
                </v-btn>
                <v-btn text color="primary" @click="$refs.dialog.save(date)">
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>

            <v-col cols="12" md="12">
              <v-text-field
                v-model="computedDateFormattedMomentjs"
                :value="computedDateFormattedMomentjs"
                append-icon="event"
                label="Date"
                dense
                outlined
                rounded
                hide-details="hint"
                :rules="[rules.required]"
                @click:append="dialog = true"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-select
                v-model="startTime"
                :items="times"
                :rules="[rules.required, rules.validTime]"
                item-text="time"
                item-value="time"
                label="Start Time"
                rounded
                outlined
                dense
                hide-details="hint"
                required
              ></v-select>
            </v-col>

            <v-col cols="12" md="6">
              <v-select
                v-model="endTime"
                :items="times"
                :rules="[rules.required, rules.validTime]"
                label="End Time"
                rounded
                outlined
                dense
                hide-details="hint"
                required
              ></v-select>
            </v-col>
            <v-col cols="12" md="12">
              <v-checkbox
                v-model="repeat"
                label="Repeat Weekly"
                hide-details="messages"
              ></v-checkbox>
            </v-col>

            <v-dialog
              ref="dialogEndRepeat"
              v-model="dialogEndRepeat"
              :return-value.sync="repeatEndDate"
              persistent
              width="290px"
            >
              <v-date-picker v-model="repeatEndDate" scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="dialogEndRepeat = false">
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.dialogEndRepeat.save(repeatEndDate)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>

            <v-col cols="12" md="12" v-show="repeat">
              <v-text-field
                v-model="computedRepeatEndDateFormattedMomentjs"
                :value="computedRepeatEndDateFormattedMomentjs"
                append-icon="event"
                label="End Repeat On"
                dense
                outlined
                rounded
                hide-details="hint"
                :rules="[rules.required]"
                @click:append="dialogEndRepeat = true"
              ></v-text-field>
            </v-col>

            <v-col cols="6" md="6" sm="12">
              <v-checkbox
                v-model="days.monday"
                label="Monday"
                hide-details="messages"
                :disabled="days.day === 'Monday'"
              ></v-checkbox>
            </v-col>
            <v-col cols="6" md="6" sm="12">
              <v-checkbox
                v-model="days.tuesday"
                label="Tuesday"
                hide-details="messages"
                :disabled="days.day === 'Tuesday'"
              ></v-checkbox>
            </v-col>
            <v-col cols="6" md="6" sm="12">
              <v-checkbox
                v-model="days.wednesday"
                label="Wednesday"
                hide-details="messages"
                :disabled="days.day === 'Wednesday'"
              ></v-checkbox>
            </v-col>
            <v-col cols="6" md="6" sm="12">
              <v-checkbox
                v-model="days.thursday"
                label="Thursday"
                hide-details="messages"
                :disabled="days.day === 'Thursday'"
              ></v-checkbox>
            </v-col>
            <v-col cols="6" md="6" sm="12">
              <v-checkbox
                v-model="days.friday"
                label="Friday"
                hide-details="messages"
                :disabled="days.day === 'Friday'"
              ></v-checkbox>
            </v-col>
            <v-col cols="6" md="6" sm="12">
              <v-checkbox
                v-model="days.saturday"
                label="Saturday"
                hide-details="messages"
                :disabled="days.day === 'Saturday'"
              ></v-checkbox>
            </v-col>
            <v-col cols="6" md="6" sm="12">
              <v-checkbox
                v-model="days.sunday"
                label="Sunday"
                hide-details="messages"
                :disabled="days.day === 'Sunday'"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import * as fb from '../../firebase'
import * as moment from 'moment'
export default {
  name: 'AddEventDialog',
  data() {
    return {
      date: new Date().toISOString().substr(0, 10),
      dialog: false,
      dialogEndRepeat: false,
      valid: false,
      repeat: false,
      name: 'Immediate Waiting Room Hours',
      eventMessage: '',
      repeatEndDate: new Date().toISOString().substr(0, 10),
      startRepeat: moment(),
      endRepeat: moment(),
      officeHours: [],
      officeHoursList: [],
      docId: '',
      days: {
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        sunday: false,
        day: ''
      },
      selectedDays: [],
      type: '',
      startTime: '',
      endTime: '',
      rules: {
        required: value => !!value || 'Required',
        validTime: () => {
          const start = moment(this.startTime, ['hh:mm A', 'hh:mm'])
          const end = moment(this.endTime, ['hh:mm A', 'hh:mm'])
          return start.isBefore(end) || 'Start time must be before end time'
        }
      }
    }
  },
  watch: {
    officeHours: function() {
      this.officeHoursList = []
      for (const item of this.officeHours) {
        this.officeHoursList.push({ ...item })
      }
    },
    type: function() {
      if (this.type === 'OfficeHours') {
        this.name = 'Immediate Waiting Room Hours'
      } else if (this.type === 'OperatingHours') {
        this.name = 'Routine Appointment Hours'
      } else {
        this.name = ''
      }
    },
    date: function() {
      this.checkMarkedDays()
    }
  },
  computed: {
    computedDateFormattedMomentjs: {
      get() {
        return this.date ? moment(this.date).format('dddd, MMMM Do YYYY') : ''
      },
      set() {}
    },
    computedRepeatEndDateFormattedMomentjs: {
      get() {
        return this.repeatEndDate
          ? moment(this.repeatEndDate).format('dddd, MMMM Do YYYY')
          : ''
      },
      set() {}
    },
    times: function() {
      const times = []
      const timesLimitted = []

      const secondsInDay = 86400
      const steps = 900 // 15 minutes
      for (let i = 0; i < secondsInDay; i += steps) {
        const d = new Date()
        d.setHours(0)
        d.setMinutes(0)
        d.setSeconds(i)

        const time = moment(
          fb.db.app.firebase_.firestore.Timestamp.fromDate(d).toDate()
        ).format('hh:mm A')

        times.push(time)
      }
      const timesStart = times.indexOf('07:00 AM')
      let index = timesStart

      while (index !== times.length) {
        timesLimitted.push(times[index])
        index++
      }

      return timesLimitted
    }
  },
  mounted() {
    this.$bind(
      'officeHours',
      fb.proxiesCollection
        .doc(fb.auth.currentUser.uid)
        .collection('OfficeHours')
    )
    this.docId = this.makeId(8)
    this.checkMarkedDays()
  },
  methods: {
    close() {
      this.$emit('closeAdd')
    },
    checkMarkedDays() {
      const start = fb.db.app.firebase_.firestore.Timestamp.fromDate(
        new Date(`${this.date.split('-').join('/')} 00:00 AM`)
      )
      const day = moment(start.toDate()).format('dddd')

      this.days = {
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        sunday: false,
        day: ''
      }
      if (day === 'Sunday') {
        this.days.sunday = true
        this.days.day = 'Sunday'
      } else if (day === 'Monday') {
        this.days.monday = true
        this.days.day = 'Monday'
      } else if (day === 'Tuesday') {
        this.days.tuesday = true
        this.days.day = 'Tuesday'
      } else if (day === 'Wednesday') {
        this.days.wednesday = true
        this.days.day = 'Wednesday'
      } else if (day === 'Thursday') {
        this.days.thursday = true
        this.days.day = 'Thursday'
      } else if (day === 'Friday') {
        this.days.friday = true
        this.days.day = 'Friday'
      } else if (day === 'Saturday') {
        this.days.saturday = true
        this.days.day = 'Saturday'
      }
    },
    addEvent() {
      const start = fb.db.app.firebase_.firestore.Timestamp.fromDate(
        new Date(`${this.date.split('-').join('/')} ${this.startTime}`)
      )

      const selectedRepeatDays = []
      if (this.days.sunday) {
        selectedRepeatDays.push(0)
      }
      if (this.days.monday) {
        selectedRepeatDays.push(1)
      }
      if (this.days.tuesday) {
        selectedRepeatDays.push(2)
      }
      if (this.days.wednesday) {
        selectedRepeatDays.push(3)
      }
      if (this.days.thursday) {
        selectedRepeatDays.push(4)
      }
      if (this.days.friday) {
        selectedRepeatDays.push(5)
      }
      if (this.days.saturday) {
        selectedRepeatDays.push(6)
      }
      this.selectedDays = []
      selectedRepeatDays.forEach(x => {
        if (
          moment()
            .day(x)
            .isBefore(moment(start.toDate()), 'day')
        ) {
          this.selectedDays.push({
            date: moment()
              .day(x)
              .add(7, 'days')
              .toISOString()
              .substr(0, 10)
              .split('-')
              .join('/')
          })
        } else {
          this.selectedDays.push({
            date: moment()
              .day(x)
              .toISOString()
              .substr(0, 10)
              .split('-')
              .join('/')
          })
        }
      })

      var color = ''
      var type = ''
      if (this.type === 'OfficeHours') {
        color = 'primary'
        type = 'OfficeHours'
      } else if (this.type === 'OperatingHours') {
        color = 'green'
        type = 'OperatingHours'
      } else {
        color = 'red'
        type = 'Other'
      }

      this.selectedDays.forEach(item => {
        if (this.repeat) {
          this.repeatEvent(color, type, item.date)
        } else if (!this.repeat) {
          this.singleEvent(color, type, item.date)
        }
      })

      this.repeat = false
    },
    async repeatEvent(color, type, date) {
      const start = fb.db.app.firebase_.firestore.Timestamp.fromDate(
        new Date(`${date} ${this.startTime}`)
      )
      const end = fb.db.app.firebase_.firestore.Timestamp.fromDate(
        new Date(`${date} ${this.endTime}`)
      )
      const day = moment(start.toDate()).format('dddd')

      const startIsSameCount = this.officeHoursList.filter(x =>
        moment(start.toDate()).isSame(moment(x.startTime.toDate()))
      ).length
      const endIsSameCount = this.officeHoursList.filter(x =>
        moment(end.toDate()).isSame(moment(x.endTime.toDate()))
      ).length
      const startIsBetweenCount = this.officeHoursList.filter(x =>
        moment(start.toDate()).isBetween(
          moment(x.startTime.toDate()),
          moment(x.endTime.toDate())
        )
      ).length

      const endIsBetweenCount = this.officeHoursList.filter(x =>
        moment(end.toDate()).isBetween(
          moment(x.startTime.toDate()),
          moment(x.endTime.toDate())
        )
      ).length

      try {
        if (
          startIsSameCount === 0 &&
          endIsSameCount === 0 &&
          startIsBetweenCount === 0 &&
          endIsBetweenCount === 0
        ) {
          const newHours = await fb.proxiesCollection
            .doc(fb.auth.currentUser.uid)
            .collection('OfficeHours')
            .doc()

          // docId = newHours.id

          newHours.set({
            name: this.name,
            startTime: start,
            endTime: end,
            day: day,
            relatedKey: this.docId,
            repeat: this.repeat,
            color: color,
            type: type
          })
        }

        // const maxDate = moment().add(6, 'month')
        const maxDate = moment(this.repeatEndDate.split('-').join('/'))

        this.startRepeat = moment(start.toDate()).add(7, 'days')
        this.endRepeat = moment(end.toDate()).add(7, 'days')

        while (this.startRepeat.isSameOrBefore(maxDate, 'day')) {
          const startIsSameCount = this.officeHoursList.filter(x =>
            this.startRepeat.isSame(moment(x.startTime.toDate()))
          ).length
          const endIsSameCount = this.officeHoursList.filter(x =>
            this.endRepeat.isSame(moment(x.endTime.toDate()))
          ).length
          const startIsBetweenCount = this.officeHoursList.filter(x =>
            this.startRepeat.isBetween(
              moment(x.startTime.toDate()),
              moment(x.endTime.toDate())
            )
          ).length

          const endIsBetweenCount = this.officeHoursList.filter(x =>
            this.endRepeat.isBetween(
              moment(x.startTime.toDate()),
              moment(x.endTime.toDate())
            )
          ).length

          if (
            startIsSameCount === 0 &&
            endIsSameCount === 0 &&
            startIsBetweenCount === 0 &&
            endIsBetweenCount === 0
          ) {
            try {
              fb.proxiesCollection
                .doc(fb.auth.currentUser.uid)
                .collection('OfficeHours')
                .doc()
                .set({
                  name: this.name,
                  startTime: fb.db.app.firebase_.firestore.Timestamp.fromDate(
                    new Date(this.startRepeat)
                  ),
                  endTime: fb.db.app.firebase_.firestore.Timestamp.fromDate(
                    new Date(this.endRepeat)
                  ),
                  repeat: this.repeat,
                  day: day,
                  relatedKey: this.docId,
                  color: color,
                  type: type
                })
            } catch (error) {
              this.error = error.message
              console.log(error)
            } finally {
              this.startRepeat = moment(this.startRepeat).add(7, 'days')
              this.endRepeat = moment(this.endRepeat).add(7, 'days')
            }
          } else {
            this.startRepeat = moment(this.startRepeat).add(7, 'days')
            this.endRepeat = moment(this.endRepeat).add(7, 'days')
          }
        }
      } catch (error) {
        this.error = error.message
        console.log(error)
      }
      this.$emit('closeAdd')
    },
    async singleEvent(color, type, date) {
      const start = fb.db.app.firebase_.firestore.Timestamp.fromDate(
        new Date(`${date} ${this.startTime}`)
      )
      const end = fb.db.app.firebase_.firestore.Timestamp.fromDate(
        new Date(`${date} ${this.endTime}`)
      )
      const day = moment(start.toDate()).format('dddd')

      const startIsSameCount = this.officeHoursList.filter(x =>
        moment(start.toDate()).isSame(moment(x.startTime.toDate()))
      ).length
      const endIsSameCount = this.officeHoursList.filter(x =>
        moment(end.toDate()).isSame(moment(x.endTime.toDate()))
      ).length
      const startIsBetweenCount = this.officeHoursList.filter(x =>
        moment(start.toDate()).isBetween(
          moment(x.startTime.toDate()),
          moment(x.endTime.toDate())
        )
      ).length

      const endIsBetweenCount = this.officeHoursList.filter(x =>
        moment(end.toDate()).isBetween(
          moment(x.startTime.toDate()),
          moment(x.endTime.toDate())
        )
      ).length

      if (
        startIsSameCount === 0 &&
        endIsSameCount === 0 &&
        startIsBetweenCount === 0 &&
        endIsBetweenCount === 0
      ) {
        try {
          const newHours = await fb.proxiesCollection
            .doc(fb.auth.currentUser.uid)
            .collection('OfficeHours')
            .doc()

          const docId = newHours.id

          newHours.set({
            name: this.name,
            startTime: start,
            endTime: end,
            day: day,
            relatedKey: docId,
            repeat: this.repeat,
            color: color,
            type: type
          })
        } catch (error) {
          this.error = error.message
          console.log(error)
        }
        this.$emit('closeAdd')
      } else {
        this.eventMessage = 'An event during this time already exists.'
      }
    },
    makeId(length) {
      var result = ''
      var characters =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
      var charactersLength = characters.length
      for (var i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        )
      }
      return result
    }
  }
}
</script>
