<template>
  <div v-if="currentUser" class="settings">
    <v-container>
      <h1 class="mb-4">Settings</h1>
      <!-- settings navigation -->
      <template>
        <v-tabs
          id="settingsTabs"
          background-color="transparent"
          next-icon="mdi-arrow-right-bold-box-outline"
          prev-icon="mdi-arrow-left-bold-box-outline"
          show-arrows
          center-active
        >
          <v-tab ripple>Waiting Room</v-tab>
          <v-tab ripple>Exam</v-tab>
          <v-tab ripple>Practice</v-tab>
          <v-tab ripple>Proxies</v-tab>
          <v-tab ripple v-if="currentUser.id == '0QH3B588JRW4ufvkphRdzGLfZxC2'"
            >Dev Only</v-tab
          >
          <v-tab-item><WaitingRoom :user="currentUser"/></v-tab-item>
          <v-tab-item><Exam :user="currentUser"/></v-tab-item>
          <v-tab-item><Practice :user="currentUser"/></v-tab-item>
          <v-tab-item><Proxies :user="currentUser"/></v-tab-item>
          <v-tab-item v-if="currentUser.id == '0QH3B588JRW4ufvkphRdzGLfZxC2'">
            <v-card class="mt-8">
              <v-card-title>Set Admin</v-card-title>
              <v-card-text>
                <v-form ref="form" v-model="valid">
                  <v-text-field
                    v-model="form.email"
                    name="email"
                    label="User Email"
                    type="text"
                    :rules="emailRules"
                    outlined
                    rounded
                    dense
                  />
                </v-form>
                <v-btn
                  :disabled="!valid"
                  :loading="loading"
                  color="primary"
                  @click="addAdmin"
                  >Add Admin</v-btn
                >
                <v-alert dense type="error" dismissible v-if="error">
                  {{ error }}</v-alert
                >
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs>
      </template>
    </v-container>
  </div>
</template>

<script>
import { functions } from '@/firebase'
import ProxyLayout from '@/layouts/ProxyLayout'
import Exam from '../components/settingsTabs/Exam'
import Practice from '../components/settingsTabs/Practice'
import WaitingRoom from '../components/settingsTabs/WaitingRoom'
import Proxies from '@/views/Proxies'
export default {
  name: 'Settings',
  props: ['user'],
  components: {
    Exam,
    Practice,
    WaitingRoom,
    Proxies
  },
  data() {
    return {
      currentUser: null,
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid'
      ],
      error: '',
      valid: false,
      loading: false,
      form: {
        email: null
      }
    }
  },
  watch: {
    user: {
      immediate: true,
      handler() {
        if (this.user) {
          this.currentUser = this.user
        }
      }
    }
  },
  created() {
    this.$emit(`update:layout`, ProxyLayout)
  },
  methods: {
    async addAdmin() {
      this.loading = true
      if (this.$refs.form.validate()) {
        const addAdmin = functions.httpsCallable('addAdmin')
        try {
          await addAdmin({
            email: this.form.email
          })
          this.loading = false
          this.$refs.form.reset()
        } catch (error) {
          this.error = error.message
          this.loading = false
        }
      }
      this.loading = false
    }
  }
}
</script>

<style scoped></style>
