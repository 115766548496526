<template>
  <div>
    <v-card class="mt-4 pa-4" rounded="lg">
      <v-card-title>
        Office Hours
        <v-btn
          class="ml-4"
          @click.stop="addDialog = true"
          fab
          dark
          x-small
          color="primary"
        >
          <v-icon dark>
            mdi-plus
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-container>
        <v-sheet tile height="auto" class="d-flex pa-4">
          <v-toolbar flat>
            <v-btn fab text small color="grey darken-2" @click="prev()">
              <v-icon small>
                mdi-chevron-left
              </v-icon>
            </v-btn>

            <v-toolbar-title v-if="$refs.calendar">
              {{ $refs.calendar.title }}
            </v-toolbar-title>
            <v-btn fab text small color="grey darken-2" @click="next()">
              <v-icon small>
                mdi-chevron-right
              </v-icon>
            </v-btn>
            <v-spacer></v-spacer>

            <v-menu v-if="$mq !== 'mobile'" bottom right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn outlined color="grey darken-2" v-bind="attrs" v-on="on">
                  <span>{{ typeToLabel[type] }}</span>
                  <v-icon right>
                    mdi-menu-down
                  </v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="type = 'day'">
                  <v-list-item-title>Day</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = 'week'">
                  <v-list-item-title>Week</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = 'month'">
                  <v-list-item-title>Month</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = '4day'">
                  <v-list-item-title>4 days</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            <div v-if="$mq !== 'mobile'" class="ml-2">
              <v-menu bottom right>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    outlined
                    color="grey darken-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <span>Days</span>
                    <v-icon right>
                      mdi-menu-down
                    </v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="weekday = [0, 1, 2, 3, 4, 5, 6]">
                    <v-list-item-title>Sun - Sat</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="weekday = [1, 2, 3, 4, 5, 6, 0]">
                    <v-list-item-title>Mon - Sun</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="weekday = [1, 2, 3, 4, 5]">
                    <v-list-item-title>Mon - Fri</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="weekday = [1, 3, 5]">
                    <v-list-item-title>Mon, Wed, Fri</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </v-toolbar>
        </v-sheet>

        <v-sheet height="600" class="pa-4 pt-0">
          <v-calendar
            v-model="value"
            ref="calendar"
            :weekdays="weekday"
            :now="today"
            :type="$mq == 'mobile' ? 'day' : type"
            :events="events"
            :event-color="getEventColor"
            :event-overlap-mode="mode"
            :event-overlap-threshold="30"
            color="primary"
            @click:event="showEvent"
            @click:more="setDayView"
            @mousedown:event="startDrag"
            @mousedown:time="startTime"
            @mousemove:time="mouseMove"
            @mouseup:time="endDrag"
            @mouseleave.native="cancelDrag"
          >
            <template v-slot:event="{ event, timed, eventSummary }">
              <div class="v-event-draggable" v-html="eventSummary()"></div>
              <div
                v-if="timed"
                class="v-event-drag-bottom"
                @mousedown.stop="extendBottom(event)"
              ></div>
            </template>
          </v-calendar>
        </v-sheet>
      </v-container>
    </v-card>

    <v-menu
      v-model="selectedOpen"
      :close-on-content-click="false"
      :activator="selectedElement"
      offset-x
    >
      <v-card color="grey lighten-4" min-width="300px" flat>
        <v-toolbar dark>
          <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="editDialog = true">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn @click="deleteDialog = true" icon>
            <v-icon>mdi-trash-can</v-icon>
          </v-btn>
          <v-btn icon color="white" @click="selectedOpen = false">
            <v-icon>mdi-close-box-multiple-outline</v-icon>
          </v-btn>
        </v-toolbar>
      </v-card>
    </v-menu>

    <v-dialog v-model="addDialog" max-width="500px" persistent>
      <AddEventDialog @closeAdd="addDialog = false" />
    </v-dialog>
    <v-dialog
      v-if="selectedEvent"
      v-model="editDialog"
      max-width="500px"
      persistent
    >
      <EditEventDialog
        :selectedEvent="selectedEvent"
        @closeEdit="editDialog = false"
      />
    </v-dialog>
    <v-dialog v-model="deleteDialog" max-width="500px">
      <v-card>
        <v-card-title>Delete Event {{ selectedEvent.id }}</v-card-title>
        <v-card-text>
          <v-col cols="12" md="12">
            <v-radio-group v-model="deleteOptions" mandatory>
              <v-radio
                label="Delete this event only"
                color="green"
                value="0"
              ></v-radio>
              <v-radio
                label="Delete this and future events"
                color="primary"
                value="1"
              ></v-radio>
              <v-radio
                label="Delete this and all related events"
                color="red"
                value="2"
              ></v-radio>
            </v-radio-group>
          </v-col>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" @click="deleteType" text>Delete</v-btn>
          <v-btn color="primary" @click="deleteDialog = false" text
            >Cancel</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import * as moment from 'moment'
import * as fb from '../../firebase'
import AddEventDialog from '../dialogs/AddEventDialog'
import EditEventDialog from '../dialogs/EditEventDialog'
export default {
  name: 'OfficeHours',
  components: {
    AddEventDialog,
    EditEventDialog
  },
  props: ['user'],
  data() {
    return {
      deleteDialog: false,
      addDialog: false,
      editDialog: false,
      today: null,
      officeHours: [],
      weekStart: null,
      weekEnd: null,
      events: [],
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      value: '',
      deleteOptions: '',
      mode: 'stack',
      type: 'week',
      typeToLabel: {
        month: 'Month',
        week: 'Week',
        day: 'Day',
        '4day': '4 Days'
      },
      weekday: [0, 1, 2, 3, 4, 5, 6],
      dragEvent: null,
      dragStart: null,
      createEvent: null,
      createStart: null,
      extendOriginal: null
    }
  },
  mounted() {
    this.todaysDate()
    this.$refs.calendar.scrollToTime('08:00')
    this.weekStart = this.$refs.calendar.lastStart.date
    this.weekEnd = this.$refs.calendar.lastEnd.date

    this.$bind(
      'officeHours',
      fb.db.collection(`Proxies/${fb.auth.currentUser.uid}/OfficeHours`)
    )
  },
  watch: {
    officeHours: function() {
      this.events = []
      for (const item of this.officeHours) {
        this.events.push({
          name: item.name,
          start: item.startTime.toDate(),
          end: item.endTime.toDate(),
          date: moment(item.startTime.toDate()),
          startTime: item.startTime,
          repeat: item.repeat,
          relatedKey: item.relatedKey,
          endTime: item.endTime,
          id: item.id,
          color: item.color,
          timed: true
        })
      }
      this.eventsCount = this.events.length
    }
  },

  methods: {
    todaysDate() {
      const today = new Date()
      this.today = moment(today).format('YYYY-MM-DD')
    },
    setDayView() {
      this.type = 'day'
    },
    next() {
      this.$refs.calendar.next()
    },
    prev() {
      this.$refs.calendar.prev()
    },
    getEventColor(event) {
      return event.color
    },
    getEventId(event) {
      return event.input.relatedKey
    },
    deleteType() {
      if (this.deleteOptions === '0') {
        this.deleteEvent()
      } else if (this.deleteOptions === '1') {
        this.deleteNowAndFuture()
      } else if (this.deleteOptions === '2') {
        this.deleteAllEvents()
      }
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event
        this.selectedElement = nativeEvent.target
        setTimeout(() => {
          this.selectedOpen = true
        }, 10)
      }

      if (this.selectedOpen) {
        this.selectedOpen = false
        setTimeout(open, 10)
      } else {
        open()
      }

      nativeEvent.stopPropagation()
    },
    async deleteEvent() {
      try {
        await fb.proxiesCollection
          .doc(fb.auth.currentUser.uid)
          .collection('OfficeHours')
          .doc(this.selectedEvent.id)
          .delete()
        this.deleteDialog = false
      } catch (error) {
        this.error = error.message
        console.log(error)
      }
    },
    async deleteNowAndFuture() {
      try {
        await fb.proxiesCollection
          .doc(fb.auth.currentUser.uid)
          .collection('OfficeHours')
          .where('relatedKey', '==', this.selectedEvent.relatedKey)
          .get()
          .then(snapshot => {
            {
              for (const item of snapshot.docs) {
                const endTime = moment(item.data().endTime.toDate())
                const selectedInterval = moment(
                  this.selectedEvent.endTime.toDate()
                )

                if (endTime.isSameOrAfter(selectedInterval, 'day')) {
                  fb.proxiesCollection
                    .doc(fb.auth.currentUser.uid)
                    .collection('OfficeHours')
                    .doc(item.id)
                    .delete()
                }
              }
            }
          })
        this.deleteDialog = false
      } catch (error) {
        this.error = error.message
        console.log(error)
      }
    },
    deleteAllEvents() {
      try {
        fb.proxiesCollection
          .doc(fb.auth.currentUser.uid)
          .collection('OfficeHours')
          .where('relatedKey', '==', this.selectedEvent.relatedKey)
          .get()
          .then(async snapshot => {
            {
              for (const item of snapshot.docs) {
                await fb.proxiesCollection
                  .doc(fb.auth.currentUser.uid)
                  .collection('OfficeHours')
                  .doc(item.id)
                  .delete()
              }
            }
          })
        this.deleteDialog = false
      } catch (error) {
        this.error = error.message
        console.log(error)
      }
    },
    startDrag({ event, timed }) {
      if (event && timed) {
        this.dragEvent = event
        this.dragTime = null
        this.extendOriginal = null
      }
    },
    startTime(tms) {
      const mouse = this.toTime(tms)
      if (this.dragEvent !== null && this.dragTime === null) {
        const start = this.dragEvent.start

        this.dragTime = mouse - start
      }
    },
    extendBottom(event) {
      this.createEvent = event
      this.createStart = event.start
      this.extendOriginal = event.end
    },
    mouseMove(tms) {
      const mouse = this.toTime(tms)

      if (this.dragEvent && this.dragTime !== null) {
        const start = this.dragEvent.start
        const end = this.dragEvent.end
        const duration = end - start
        const newStartTime = mouse - this.dragTime
        const newStart = this.roundTime(newStartTime)
        const newEnd = newStart + duration

        this.dragEvent.start = newStart
        this.dragEvent.end = newEnd
      } else if (this.createEvent && this.createStart !== null) {
        const mouseRounded = this.roundTime(mouse, false)
        const min = Math.min(mouseRounded, this.createStart)
        const max = Math.max(mouseRounded, this.createStart)

        this.createEvent.start = min
        this.createEvent.end = max
      }
    },
    endDrag() {
      if (this.dragEvent) {
        this.updateEvent(this.dragEvent)
      } else if (this.createEvent) {
        this.updateEvent(this.createEvent)
      }
      this.dragTime = null
      this.dragEvent = null
      this.createEvent = null
      this.createStart = null
      this.extendOriginal = null
    },
    cancelDrag() {
      if (this.createEvent) {
        if (this.extendOriginal) {
          this.createEvent.end = this.extendOriginal
        } else {
          const i = this.events.indexOf(this.createEvent)
          if (i !== -1) {
            this.events.splice(i, 1)
          }
        }
      }

      this.createEvent = null
      this.createStart = null
      this.dragTime = null
      this.dragEvent = null
    },
    async updateEvent(event) {
      const startTime = fb.db.app.firebase_.firestore.Timestamp.fromDate(
        new Date(`${moment(event.start)}`)
      )
      const endTime = fb.db.app.firebase_.firestore.Timestamp.fromDate(
        new Date(`${moment(event.end)}`)
      )

      try {
        await fb.proxiesCollection
          .doc(fb.auth.currentUser.uid)
          .collection('OfficeHours')
          .doc(event.id)
          .set(
            {
              startTime: startTime,
              endTime: endTime
            },
            { merge: true }
          )
      } catch (error) {
        this.error = error.message
        console.log(error)
      }
    },
    roundTime(time, down = true) {
      const roundTo = 15 // minutes
      const roundDownTime = roundTo * 60 * 1000

      return down
        ? time - (time % roundDownTime)
        : time + (roundDownTime - (time % roundDownTime))
    },
    toTime(tms) {
      return new Date(
        tms.year,
        tms.month - 1,
        tms.day,
        tms.hour,
        tms.minute
      ).getTime()
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a
    },
    rndElement(arr) {
      return arr[this.rnd(0, arr.length - 1)]
    }
  }
}
</script>

<style scoped lang="scss">
.v-event-draggable {
  padding-left: 6px;
}

.v-event-timed {
  user-select: none;
  -webkit-user-select: none;
}

.v-event-drag-bottom {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 4px;
  height: 4px;
  cursor: ns-resize;

  &::after {
    display: none;
    position: absolute;
    left: 50%;
    height: 4px;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    width: 16px;
    margin-left: -8px;
    opacity: 0.8;
    content: '';
  }

  &:hover::after {
    display: block;
  }
}
</style>
