<template>
  <v-card class="mt-4 pa-4" rounded="lg">
    <v-card-title>Exam Outline</v-card-title>
    <v-card-text>
      <v-card>
        <ckeditor
          :editor="editor"
          v-model="editorData"
          :config="editorConfig"
        ></ckeditor>
      </v-card>
      <v-row>
        <v-col cols="12" md="6">
          <v-card-title class="pl-0 pb-0">Health Data Settings</v-card-title>
          <v-checkbox
            v-model="healthData.HR"
            label="Heart Rate"
            hide-details
          ></v-checkbox>
          <v-checkbox
            v-model="healthData.WHR"
            label="Walking Heart Rate"
            hide-details
          ></v-checkbox>
          <v-checkbox
            v-model="healthData.BP"
            label="Blood Pressure"
            hide-details
          ></v-checkbox>
          <v-checkbox
            v-model="healthData.BG"
            label="Blood Glucose"
            hide-details
          ></v-checkbox>
          <v-checkbox
            v-model="healthData.PO"
            label="Pulse Ox"
            hide-details
          ></v-checkbox>
          <v-checkbox
            v-model="healthData.BW"
            label="BMI & Weight"
            hide-details
          ></v-checkbox>
          <v-checkbox
            v-model="healthData.CFL"
            label="Cardio Fitness Level"
            hide-details
          ></v-checkbox>
          <v-checkbox
            v-model="healthData.ECG"
            label="Electrocardiogram"
            hide-details
          ></v-checkbox>
        </v-col>
        <v-col cols="12" md="6">
          <v-card-title class="pl-0 pb-0">Exam Settings</v-card-title>
          <v-checkbox
            v-model="examSettings.medicalHistory"
            label="Medical History"
            hide-details
          ></v-checkbox>
          <v-checkbox
            v-model="examSettings.symptoms"
            label="Symptoms"
            hide-details
          ></v-checkbox>
          <v-checkbox
            v-model="examSettings.medicalDocuments"
            label="Medical Documents"
            hide-details
          ></v-checkbox>
          <v-checkbox
            v-model="examSettings.medications"
            label="Medications"
            hide-details
          ></v-checkbox>
          <v-checkbox
            v-model="examSettings.vitals"
            label="Vitals"
            hide-details
          ></v-checkbox>
          <v-checkbox
            v-model="examSettings.inviteRelative"
            label="Invite Relative"
            hide-details
          ></v-checkbox>
          <v-checkbox
            v-model="examSettings.examComponents"
            label="Exam Components"
            hide-details
          ></v-checkbox>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions class="ma-2">
      <v-btn @click="save()" color="primary" :block="$mq === 'mobile'"
        >Save</v-btn
      >

      <v-chip v-if="indicate" color="grey" outlined label class="ml-4">
        <v-icon left>
          mdi-checkbox-marked-outline
        </v-icon>
        Saved
      </v-chip>
    </v-card-actions>
  </v-card>
</template>
<script>
import * as fb from '../../firebase'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
export default {
  name: 'ExamOutline',
  data() {
    return {
      indicate: false,
      editor: ClassicEditor,
      editorData: '<p></p>',
      editorConfig: {
        removePlugins: ['MediaEmbed', 'Table', 'TableToolbar', 'BlockQuote'],
        toolbar: [
          'heading',
          'bold',
          'italic',
          'link',
          'bulletedList',
          'numberedList',
          'indent',
          'undo',
          'redo'
        ]
      },
      currentUser: {},
      examSettings: {},
      healthData: {}
    }
  },
  watch: {
    indicate: function() {
      setTimeout(
        function() {
          this.indicate = false
        }.bind(this),
        2000
      )
      console.log('updated')
    }
  },
  async mounted() {
    const user = await fb.proxiesCollection.doc(fb.auth.currentUser.uid).get()
    this.currentUser = Object.assign({ id: user.id, ...user.data() })

    if (this.currentUser.examOutline) {
      this.editorData = this.currentUser.examOutline
    } else {
      this.editorData = '<p></p>'
    }

    if (!this.currentUser.healthData) {
      this.currentUser.healthData = {
        HR: true,
        WHR: true,
        BP: true,
        BG: true,
        PO: true,
        BW: true,
        CFL: true,
        ECG: true
      }
    }
    if (this.currentUser.healthData) {
      this.healthData = this.currentUser.healthData
    }

    if (!this.currentUser.examSettings) {
      this.currentUser.examSettings = {
        medicalHistory: true,
        symptoms: true,
        medicalDocuments: true,
        medications: true,
        vitals: true,
        inviteRelative: true,
        examComponents: true
      }
    }
    if (this.currentUser.examSettings) {
      this.examSettings = this.currentUser.examSettings
    }
  },
  methods: {
    async save() {
      try {
        await fb.proxiesCollection.doc(fb.auth.currentUser.uid).set(
          {
            examOutline: this.editorData,
            healthData: {
              HR: this.healthData.HR,
              WHR: this.healthData.WHR,
              BP: this.healthData.BP,
              BG: this.healthData.BG,
              PO: this.healthData.PO,
              BW: this.healthData.BW,
              CFL: this.healthData.CFL,
              ECG: this.healthData.ECG
            },
            examSettings: {
              medicalHistory: this.examSettings.medicalHistory,
              symptoms: this.examSettings.symptoms,
              medicalDocuments: this.examSettings.medicalDocuments,
              medications: this.examSettings.medications,
              vitals: this.examSettings.vitals,
              inviteRelative: this.examSettings.inviteRelative,
              examComponents: this.examSettings.examComponents
            }
          },
          { merge: true }
        )
        this.indicate = true
      } catch (error) {
        this.error = error.message
        console.log(error)
      }
    },
    async updateProxies() {
      await fb.db
        .collection('Proxies')
        .get()
        .then(async snap => {
          const batchSize = snap.size
          if (batchSize > 0) {
            const batch = fb.db.batch()
            snap.docs.forEach(doc => {
              batch.update(doc.ref, {
                healthData: {
                  HR: true,
                  WHR: true,
                  BP: true,
                  BG: true,
                  PO: true,
                  BW: true,
                  CFL: true,
                  ECG: true
                }
              })
            })
            await batch.commit()
          }
        })
    }
  }
}
</script>
