<template>
  <div>
    <!-- exam tab -->
    <ExamOutline />

    <v-card class="mt-4 pa-4" rounded="lg">
      <v-card-title
        >Physical Exam Component
        <v-btn
          :class="$mq === 'mobile' ? 'mt-2 pa-4' : 'ml-4'"
          @click.stop="addComponentDialog = true"
          :fab="$mq !== 'mobile'"
          :outlined="$mq === 'mobile'"
          :block="$mq === 'mobile'"
          dark
          x-small
          color="primary"
        >
          <v-icon dark>
            mdi-plus
          </v-icon>
        </v-btn></v-card-title
      >
      <v-card-text>
        <v-simple-table width="100%">
          <tbody>
            <tr
              v-for="item in components"
              style="cursor: pointer;"
              :key="item.id"
            >
              <td width="3%" @click="viewComponent(item)">
                <v-icon v-if="item.type == 'Audio'" color="black"
                  >volume_up</v-icon
                >
                <v-icon v-else color="black">ondemand_video</v-icon>
              </td>
              <td
                width="90%"
                class="black--text font-weight-bold"
                @click="viewComponent(item)"
              >
                {{ item.name }}
              </td>
              <td>
                <v-menu top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon color="black" dark v-bind="attrs" v-on="on">
                      <v-icon>more_horiz</v-icon>
                    </v-btn>
                  </template>

                  <v-list v-if="item.owner">
                    <v-list-item>
                      <v-list-item-title>
                        <v-icon class="mr-2" @click="editItem(item)">
                          mdi-pencil-outline
                        </v-icon>
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title>
                        <v-icon color="red" @click="deleteItem(item)">
                          mdi-trash-can
                        </v-icon>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>

                  <v-list v-else>
                    <v-list-item>
                      <v-list-item-title>
                        <v-icon class="mr-2" @click="viewComponent(item)">
                          mdi-eye
                        </v-icon>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card-text>
      <v-card-actions class="ma-2">
        <v-btn
          color="primary"
          :block="$mq === 'mobile'"
          @click="manageExamDialog = true"
          >Manage My Exam</v-btn
        >
        <v-btn
          v-if="currentUserId === adminUser"
          color="primary"
          outlined
          :block="$mq === 'mobile'"
          @click="manageDefaultExamDialog = true"
          >Manage Default Exam</v-btn
        >
      </v-card-actions>
    </v-card>
    <!-- :height="$mq === 'mobile' ? 635 : 740" -->
    <v-row v-if="false">
      <v-col cols="12">
        <v-card class="mt-4 pa-4" rounded="lg">
          <v-card-title
            >Stethoscope Recording
            <br v-if="$mq === 'mobile'" />Length</v-card-title
          >
          <v-col :class="$mq === 'mobile' ? '' : 'd-flex'">
            <v-select
              v-model="recordingSelect"
              :hint="`${recordingSelect.secText}`"
              :items="recordingItems"
              item-text="seconds"
              item-value="secText"
              label="seconds"
              persistent-hint
              return-object
              rounded
              outlined
              dense
            ></v-select>

            <br v-if="$mq === 'mobile'" />
            <v-btn
              color="primary"
              :class="$mq === 'mobile' ? '' : 'ml-4'"
              :block="$mq === 'mobile'"
              @click="setRecordingTime"
              >update</v-btn
            >
          </v-col>
          <v-chip v-if="indicate" color="grey" outlined label class="ml-4">
            <v-icon left>
              mdi-checkbox-marked-outline
            </v-icon>
            Saved
          </v-chip>
        </v-card>
      </v-col>
    </v-row>
    <!-- add component dialog -->
    <v-dialog v-model="addComponentDialog" max-width="1060px">
      <AddExamComponentDialog @closeAddComponent="closeAddComponent" />
    </v-dialog>

    <!-- Edit component dialog -->
    <v-dialog v-model="editDialog" max-width="1060px">
      <EditExamComponentDialog
        v-if="selectedComponent.id"
        :editItemId="selectedComponent.id"
        @closeEdit="closeEdit"
      />
    </v-dialog>

    <!-- view component dialog -->
    <v-dialog v-model="viewComponentDialog" max-width="500px">
      <v-card>
        <v-container>
          <v-row align="center">
            <v-col cols="12" md="10" sm="10">
              <v-card-title>{{ selectedComponent.name }}</v-card-title>
            </v-col>
            <v-col cols="12" md="2" sm="2" v-if="$mq !== 'mobile'">
              <v-icon v-if="selectedComponent.type == 'Audio'" color="black"
                >volume_up</v-icon
              >
              <v-icon v-else color="black">ondemand_video</v-icon>
            </v-col>
            <v-card-text>
              <v-col cols="12">
                {{ selectedComponent.abbrev }}
              </v-col>
              <v-col cols="12">{{ selectedComponent.description }}</v-col>
              <v-col cols="12" v-if="selectedComponent.primaryImage">
                <v-card>
                  <v-img
                    :src="selectedComponent.primaryImage"
                    max-height="200"
                    contain
                    class="grey darken-4"
                  ></v-img>
                  <v-card-title class="subtitle-2">
                    Primary Image
                  </v-card-title>
                </v-card>
              </v-col>
              <v-col
                cols="12"
                v-for="file in selectedComponentFiles"
                :key="file.id"
              >
                <v-card
                  v-if="file.type === 'image/png' || file.type === 'image/jpeg'"
                >
                  <v-img
                    :src="file.url"
                    max-height="200"
                    contain
                    class="grey darken-4"
                  ></v-img>
                  <v-card-title class="subtitle-2">
                    {{ file.name }}
                  </v-card-title>
                </v-card>
                <v-card
                  v-else-if="
                    file.type === 'audio/wav' || file.type === 'audio/mpeg'
                  "
                  class="pa-4"
                >
                  <UniversalAudioPlayer
                    v-if="file.url"
                    :selectedFile="file.url"
                  />
                  <v-card-title class="subtitle-2">
                    {{ file.name }}
                  </v-card-title>
                </v-card>
                <v-card
                  v-else-if="
                    file.type === 'video/webm' || file.type === 'video/mp4'
                  "
                  class="pa-4"
                >
                  <div v-if="file.url">
                    <VuePlyr style="max-width: 100%">
                      <video
                        style="max-width: 100%; max-height: 720px;"
                        controls
                      >
                        <source :src="file.url" type="video/mp4" />
                      </video>
                    </VuePlyr>
                  </div>
                  <v-card-title class="subtitle-2">
                    {{ file.name }}
                  </v-card-title>
                </v-card>
              </v-col>
            </v-card-text>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
    <!-- delete dialog -->
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="headline"
          >Are you sure you want to delete this <br />component?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="closeDelete">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="deleteItemConfirm"
            >OK</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      v-model="manageExamDialog"
    >
      <ManageExamDialog
        @closeManage="manageExamDialog = false"
        :examComponents="components"
        :currentUserId="currentUserId"
      />
    </v-dialog>
    <v-dialog
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      v-model="manageDefaultExamDialog"
    >
      <ManageDefaultExamDialog
        @closeManage="manageDefaultExamDialog = false"
        :examComponents="components"
        :currentUserId="currentUserId"
      />
    </v-dialog>
  </div>
</template>

<script>
import * as fb from '../../firebase'
import ExamOutline from './ExamOutline'
import AddExamComponentDialog from '../dialogs/AddExamComponentDialog'
import ManageExamDialog from '../dialogs/ManageExamDialog'
import ManageDefaultExamDialog from '../dialogs/ManageDefaultExamDialog'
import EditExamComponentDialog from '../dialogs/EditExamComponentDialog'
import UniversalAudioPlayer from '@/components/UniversalAudioPlayer'
// NEW Default ID XvBJ18mzRmZXYwVnpVxk6d9mX072
//Old default ID KQHbFPhkGiTWDSXUqVDgqNupWfw2

export default {
  name: 'Exam',
  components: {
    AddExamComponentDialog,
    EditExamComponentDialog,
    ExamOutline,
    ManageExamDialog,
    ManageDefaultExamDialog,
    UniversalAudioPlayer
  },
  data() {
    return {
      adminUser: 'XvBJ18mzRmZXYwVnpVxk6d9mX072',
      indicate: false,
      toggle_exclusive: 2,
      toggle_multiple: [1, 2, 3],
      addComponentDialog: false,
      viewComponentDialog: false,
      dialogDelete: false,
      editDialog: false,
      manageExamDialog: false,
      manageDefaultExamDialog: false,
      rules: {
        required: value => !!value || 'Required.'
      },
      recordingSelect: { seconds: 15, secText: '15 seconds' },
      recordingItems: [
        { seconds: 5, secText: '5 seconds' },
        { seconds: 10, secText: '10 seconds' },
        { seconds: 15, secText: '15 seconds' },
        { seconds: 30, secText: '30 seconds' },
        { seconds: 60, secText: '60 seconds' },
        { seconds: 90, secText: '90 seconds' },
        { seconds: 120, secText: '120 seconds' }
      ],
      examComponents: [],
      components: [],
      practiceId: '',
      privateComponents: [],
      publicComponents: [],
      selectedComponent: {
        id: '',
        name: '',
        type: '',
        abbrev: '',
        description: ''
      },
      selectedComponentFiles: [],
      currentUserId: ''
    }
  },
  watch: {
    privateComponents: function() {
      //this.examComponents = []
      this.filter()
    },
    publicComponents: function() {
      //this.examComponents = []
      this.filter()
    },
    examComponents: function() {
      this.protect()
    },
    selectedComponent: async function() {
      this.selectedComponentFiles = []
      await fb.examComponentsCollection
        .doc(this.selectedComponent.id)
        .collection('Files')
        .get()
        .then(snap => {
          for (const doc of snap.docs) {
            this.selectedComponentFiles.push({ id: doc.id, ...doc.data() })
          }
        })
    },
    indicate: function() {
      setTimeout(
        function() {
          this.indicate = false
        }.bind(this),
        2000
      )
    }
  },
  async mounted() {
    const currentUser = await fb.proxiesCollection
      .doc(fb.auth.currentUser.uid)
      .get()
    this.currentUserId = currentUser.id
    if (currentUser.data().recordTime) {
      this.recordingSelect = {
        seconds: currentUser.data().recordTime,
        secText: `${currentUser.data().recordTime} seconds`
      }
    }

    const practice = await fb.practicesCollection
      .doc(currentUser.data().practiceId)
      .get()
    this.practiceId = practice.id

    this.$bind(
      'privateComponents',
      fb.examComponentsCollection.where('private', '==', true)
    )

    this.$bind(
      'publicComponents',
      fb.examComponentsCollection.where('private', '==', false)
    )

    this.filter()
  },
  methods: {
    setRecordingTime() {
      try {
        fb.proxiesCollection.doc(this.currentUserId).set(
          {
            recordTime: this.recordingSelect.seconds
          },
          { merge: true }
        )
        this.indicate = true
      } catch (error) {
        console.log(error)
      }
    },
    closeAddComponent() {
      this.addComponentDialog = false
    },
    closeEdit() {
      this.editDialog = false
    },
    filter() {
      this.examComponents = []

      for (const component of this.privateComponents) {
        this.examComponents.push({
          id: component.id,
          ...component
        })
      }

      for (const component of this.publicComponents) {
        this.examComponents.push({
          id: component.id,
          ...component
        })
      }
      this.examComponents.sort((a, b) => (a.name > b.name ? 1 : -1))
    },
    protect() {
      this.components = []
      for (const component of this.examComponents) {
        let isOwner = false
        if (component.creatorId === fb.auth.currentUser.uid) {
          isOwner = true
        }
        this.components.push({
          owner: isOwner,
          ...component
        })
      }
    },
    async viewComponent(item) {
      this.viewComponentDialog = true
      this.selectedComponent = Object.assign(item)
    },
    editItem(item) {
      this.selectedComponent.id = item.id
      this.editDialog = true
    },
    deleteItem(item) {
      this.selectedComponent = Object.assign({}, item)
      this.selectedComponent.id = item.id
      this.dialogDelete = true
    },
    closeDelete() {
      this.dialogDelete = false
    },

    async deleteItemConfirm() {
      await fb.examComponentsCollection
        .doc(this.selectedComponent.id)
        .collection('Files')
        .get()
        .then(snapshot => {
          var batch = fb.db.batch()
          snapshot.forEach(doc => {
            batch.delete(doc.ref)
          })
          batch.commit()
        })
        .then(async () => {
          await fb.examComponentsCollection
            .doc(this.selectedComponent.id)
            .delete()
          this.closeDelete()
        })
    }
  }
}
</script>
<style>
audio {
  width: 100%;
}
canvas {
  width: 100%;
}
</style>
