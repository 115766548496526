import { render, staticRenderFns } from "./OfficeHours.vue?vue&type=template&id=f73831d0&scoped=true"
import script from "./OfficeHours.vue?vue&type=script&lang=js"
export * from "./OfficeHours.vue?vue&type=script&lang=js"
import style0 from "./OfficeHours.vue?vue&type=style&index=0&id=f73831d0&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f73831d0",
  null
  
)

export default component.exports