<template>
  <div>
    <v-card class="mt-4 pa-4" rounded="lg">
      <v-card-title>Waiting Room Doctor Link</v-card-title>
      <v-card-text>
        <v-form v-model="isValid" @submit.prevent="checkAvailable">
          <v-row>
            <v-col>
              <v-text-field
                v-model="doctorLink.doctorLink"
                label="Link"
                required
                outlined
                :hint="linkPreview"
                :rules="linkRule"
                dense
                persistent-hint
              ></v-text-field>
            </v-col>
            <v-col>
              <v-btn
                color="primary"
                @click.prevent="checkAvailable"
                :block="$mq === 'mobile'"
                :disabled="!isValid"
              >
                Save
              </v-btn>
              <v-chip v-if="indicate" color="grey" outlined label class="ml-4">
                <v-icon left>
                  mdi-checkbox-marked-outline
                </v-icon>
                Saved
              </v-chip>
              <v-chip v-if="error" color="error" outlined label class="ml-4">
                {{ errorMsg }}
              </v-chip>
            </v-col>
          </v-row>
        </v-form>

        <v-switch
          v-model="waitingRoomSwitch"
          :label="
            waitingRoomSwitch ? 'Waiting Room Open' : 'Waiting Room Closed'
          "
          color="primary"
          hide-details
        ></v-switch>
      </v-card-text>
      <v-card-title
        >Appointment Settings <v-spacer></v-spacer>
        <v-chip v-if="indicate2" color="grey" outlined label class="ml-4">
          <v-icon left>
            mdi-checkbox-marked-outline
          </v-icon>
          Saved
        </v-chip></v-card-title
      >
      <v-card-text>
        <v-row>
          <v-col cols="12" md="5">
            <v-select
              v-model="returningAppt"
              :hint="`${returningAppt.timeText}`"
              :items="apptTimes"
              item-text="minutes"
              item-value="timeText"
              label="Established Patient"
              persistent-hint
              return-object
              outlined
              dense
            ></v-select>
          </v-col>

          <v-col cols="12" md="5">
            <v-select
              v-model="firstAppt"
              :hint="`${firstAppt.timeText}`"
              :items="apptTimes"
              item-text="minutes"
              item-value="timeText"
              label="New Patient"
              persistent-hint
              return-object
              outlined
              dense
            ></v-select>
          </v-col>

          <v-col cols="12" md="2">
            <br v-if="$mq === 'mobile'" />
            <v-btn
              color="primary"
              :class="$mq === 'mobile' ? '' : 'ml-4'"
              :block="$mq === 'mobile'"
              @click="setAppointmentTimes"
              >update</v-btn
            >
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-title>Waiting Room Background Image</v-card-title>
      <v-card-text>
        <img
          v-if="user.wrImageURL"
          :src="user.wrImageURL"
          style="width: 100%; max-width: 412px;"
        />
        <img
          v-else
          src="../../assets/waitingRoom_default.png"
          style="width: 100%; max-width: 412px;"
        />
      </v-card-text>
      <v-card-actions class="ma-2">
        <v-dialog v-model="dialog" max-width="600px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              dark
              v-bind="attrs"
              v-on="on"
              :block="$mq === 'mobile'"
            >
              Change
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">Waiting Room Image</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-file-input
                      style="margin-top: 32px;"
                      accept="image/*"
                      placeholder="Choose a photo"
                      label="Photo"
                      outlined
                      rounded
                      :loading="loading"
                      dense
                      @change="uploadFile"
                    ></v-file-input>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialog = false">
                Close
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card-actions>
    </v-card>
    <OfficeHours :user="user" />
  </div>
</template>

<script>
import * as fb from '../../firebase'
import OfficeHours from './OfficeHours'
export default {
  name: 'WaitingRoom',
  components: { OfficeHours },
  data() {
    return {
      waitingRoomSwitch: false,
      loading: false,
      dialog: false,
      indicate: false,
      indicate2: false,
      error: false,
      errorMsg: '',
      doctorLink: {},
      linkPreview: process.env.VUE_APP_URL,
      isAvailable: false,
      isValid: false,
      linkRule: [
        value => !!value || 'Required.',
        value => {
          const pattern = /^(?=.*[a-z])[a-z0-9]{4,20}$/
          return pattern.test(value) || 'Lowercase letters and numbers only'
        }
      ],
      user: {
        wrImageURL: '',
        id: ''
      },
      returningAppt: { minutes: 15, secText: '15 minutes' },
      firstAppt: { minutes: 15, secText: '15 minutes' },
      apptTimes: [
        { minutes: 15, timeText: '15 minutes' },
        { minutes: 20, timeText: '20 minutes' },
        { minutes: 30, timeText: '30 minutes' },
        { minutes: 40, timeText: '40 minutes' },
        { minutes: 45, timeText: '45 minutes' }
      ]
    }
  },
  async mounted() {
    const currentUser = await fb.proxiesCollection
      .doc(fb.auth.currentUser.uid)
      .get()
    this.user.id = currentUser.id
    this.user.name = currentUser.data().fname + ' ' + currentUser.data().lname
    this.user.practiceId = currentUser.data().practiceId

    if (currentUser.data().wrImageURL) {
      this.user.wrImageURL = currentUser.data().wrImageURL
    }

    if (currentUser.data().isWaitingRoomOpen !== null) {
      this.waitingRoomSwitch = currentUser.data().isWaitingRoomOpen
    }

    if (currentUser.data().returningAppt) {
      this.returningAppt = {
        minutes: currentUser.data().returningAppt,
        timeText: `${currentUser.data().returningAppt} minutes`
      }
    }
    if (currentUser.data().firstAppt) {
      this.firstAppt = {
        minutes: currentUser.data().firstAppt,
        timeText: `${currentUser.data().firstAppt} minutes`
      }
    }

    //this.$bind('doctorLink', fb.db.doc(`Doctor_Links/${this.user.id}`))
    await fb.db
      .collection('Doctor_Links')
      .where('doctorId', '==', this.user.id)
      .get()
      .then(snap => {
        for (const item of snap.docs) {
          if (item.data().doctorId === this.user.id) {
            this.doctorLink = Object.assign({ id: item.id, ...item.data() })
          }
        }
      })

    if (this.doctorLink.doctorLink) {
      this.linkPreview = process.env.VUE_APP_URL + this.doctorLink.doctorLink
    }
  },
  watch: {
    waitingRoomSwitch: function() {
      this.changeWaitingRoomStatus()
    },
    doctorLink: function() {
      if (this.doctorLink.doctorLink) {
        this.linkPreview = process.env.VUE_APP_URL + this.doctorLink.doctorLink
      }
    },
    indicate: function() {
      setTimeout(
        function() {
          this.indicate = false
        }.bind(this),
        2000
      )
    },
    indicate2: function() {
      setTimeout(
        function() {
          this.indicate2 = false
        }.bind(this),
        2000
      )
    },
    error: function() {
      setTimeout(
        function() {
          this.error = false
        }.bind(this),
        4000
      )
    }
  },
  methods: {
    setAppointmentTimes() {
      // console.log('set appointment times')
      try {
        fb.proxiesCollection.doc(this.user.id).set(
          {
            returningAppt: this.returningAppt.minutes,
            firstAppt: this.firstAppt.minutes
          },
          { merge: true }
        )
        this.indicate2 = true
      } catch (error) {
        console.log(error)
      }
      this.indicate2 = true
    },
    uploadFile(file) {
      if (!file) return
      this.loading = true

      const reader = new FileReader()

      reader.onload = event => {
        this.user.wrImageURL = event.target.result
      }

      reader.readAsDataURL(file)

      const storageRef = fb.storage.ref()
      const photoRef = storageRef.child(
        `Practices/${this.user.id}/photos/${file.name}`
      )
      const uploadTask = photoRef.put(file)

      uploadTask.on(
        'state_changed',
        snapshot => {
          console.log(snapshot.state)
        },
        error => {
          console.log(error)
        },
        async () => {
          const downloadURL = await uploadTask.snapshot.ref.getDownloadURL()
          fb.db
            .doc(`Proxies/${this.user.id}`)
            .set({ wrImageURL: downloadURL }, { merge: true })
        }
      )
      this.loading = false
      this.dialog = false
    },
    async checkAvailable() {
      var existingLinks = []
      var existingCount = 0
      if (this.doctorLink.doctorLink) {
        try {
          await fb.db
            .collection('Doctor_Links')
            .where('doctorLink', '==', this.doctorLink.doctorLink)
            .get()
            .then(snap => {
              for (const item of snap.docs) {
                existingLinks.push({ id: item.id, ...item.data() })
              }
            })

          existingCount = existingLinks.filter(item => item.id !== this.user.id)
            .length

          if (existingCount === 0) {
            // this.isAvailable = true
            this.updateUrl()
          } else {
            // this.isAvailable = false
            this.errorMsg = 'Link is taken'
            this.error = true
          }
        } catch (error) {
          console.log(error)
        }
      } else {
        this.errorMsg = 'Link is required'
        this.error = true
      }
    },
    updateUrl() {
      if (this.doctorLink.doctorLink) {
        try {
          fb.db
            .collection('Doctor_Links')
            .doc(this.user.id)
            .set(
              {
                doctorId: this.user.id,
                practiceId: this.user.practiceId,
                userName: this.user.name,
                doctorLink: this.doctorLink.doctorLink
              },
              { merge: true }
            )
        } catch (error) {
          console.log(error)
        }
        this.indicate = true
      } else {
        this.errorMsg = 'Link is required'
        this.error = true
      }
    },
    changeWaitingRoomStatus() {
      try {
        fb.proxiesCollection.doc(this.user.id).set(
          {
            isWaitingRoomOpen: this.waitingRoomSwitch
          },
          { merge: true }
        )
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>
