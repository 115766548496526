<template>
  <div class="proxies">
    <v-container>
      <div class="d-flex align-center">
        <h1>Proxies</h1>
        <v-btn
          class="ml-4"
          @click.stop="addDialog = true"
          fab
          dark
          x-small
          color="primary"
        >
          <v-icon dark>
            mdi-plus
          </v-icon>
        </v-btn>
      </div>

      <br />
      <v-card rounded="lg">
        <v-data-table
          :headers="headers"
          :items="proxies"
          :page.sync="page"
          :items-per-page="itemsPerPage"
          :search="search"
          hide-default-footer
          @page-count="pageCount = $event"
          rounded="lg"
        >
          <template v-slot:top>
            <v-toolbar rounded="lg" flat>
              <v-toolbar-title
                ><v-text-field
                  filled
                  single-line
                  clearable
                  dense
                  hide-details
                  placeholder="Search"
                  background-color="white"
                  prepend-inner-icon="mdi-magnify"
                  class="search"
                  v-model="search"
                  style="width: 350px;"
                ></v-text-field
              ></v-toolbar-title>

              <v-spacer></v-spacer>
              <!-- edit dialog -->
              <v-dialog v-model="dialog" max-width="500px">
                <v-card>
                  <v-card-title>
                    <span class="headline">Edit Proxy</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12" sm="6" md="6">
                          <v-text-field
                            v-model="selectedProxy.fname"
                            label="First Name"
                            outlined
                            disabled
                            rounded
                            dense
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                          <v-text-field
                            v-model="selectedProxy.lname"
                            label="Last Name"
                            outlined
                            disabled
                            rounded
                            dense
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="12">
                          <v-text-field
                            v-model="selectedProxy.displayName"
                            label="Display Name"
                            outlined
                            disabled
                            rounded
                            dense
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                          <v-text-field
                            v-model="selectedProxy.phoneNumber"
                            label="Phone Number"
                            outlined
                            rounded
                            dense
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                          <v-select
                            v-model="selectedProxy.role"
                            label="Role*"
                            :items="roles"
                            item-text="abbr"
                            item-value="role"
                            outlined
                            dense
                            rounded
                            required
                            :rules="[rules.required]"
                          ></v-select>
                        </v-col>
                        <v-col cols="12" sm="6" md="12">
                          <v-text-field
                            v-model="selectedProxy.email"
                            label="Email"
                            outlined
                            disabled
                            rounded
                            dense
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">
                      Cancel
                    </v-btn>
                    <v-btn color="blue darken-1" text @click="updateUser">
                      Update
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <!-- delete dialog -->
              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="headline"
                    >Are you sure you want to delete this user?</v-card-title
                  >
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDelete"
                      >Cancel</v-btn
                    >
                    <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                      >OK</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon
              v-if="item.id !== practiceId && item.id !== currentUser"
              class="mr-2"
              @click="editItem(item)"
            >
              mdi-pencil-outline
            </v-icon>
            <v-icon
              v-if="item.id !== practiceId && item.id !== currentUser"
              color="red"
              @click="deleteItem(item)"
            >
              mdi-trash-can
            </v-icon>
            <v-btn v-if="item.id === practiceId" disabled color="primary" text>
              Owner
            </v-btn>
          </template>
          <template v-slot:no-data>
            No proxies
          </template>
        </v-data-table>
        <div class="d-flex pt-2">
          <v-pagination
            class="justify-left ml-3 mb-6"
            v-model="page"
            :length="pageCount"
            :total-visible="7"
          ></v-pagination>
        </div>
      </v-card>
      <v-card rounded="lg" class="mt-4">
        <v-card-title>
          Invited
        </v-card-title>
        <v-data-table :headers="invitesHeader" :items="invites" rounded="lg">
          <template v-slot:item.actions="{ item }">
            <v-btn @click="resendInvite(item)" class="mr-2" color="primary" text
              >Resend Invite</v-btn
            >
            <v-btn @click="deleteInviteSet(item)" color="red" text
              >Cancel Invite</v-btn
            >
          </template>
        </v-data-table>
      </v-card>
    </v-container>
    <v-dialog v-model="addDialog" max-width="1060px">
      <AddProxyDialog @closeDialog="closeAdd()" />
    </v-dialog>
    <v-dialog
      v-model="deleteInviteDialog"
      :key="selectedInvite.id"
      max-width="300px"
    >
      <v-card>
        <v-card-title>Are you sure?</v-card-title>
        <v-card-actions>
          <v-btn @click="cancelInvite(selectedInvite)" color="primary" text
            >Yes</v-btn
          >
          <v-btn @click="deleteInviteDialog = false" color="red" text>No</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import * as fb from '../firebase'
import AddProxyDialog from '../components/dialogs/AddProxyDialog'
export default {
  name: 'Proxies',
  components: {
    AddProxyDialog
  },
  data() {
    return {
      dialog: false,
      dialogDelete: false,
      addDialog: false,
      deleteInviteDialog: false,
      selectedInvite: {},
      search: '',
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      currentUser: '',
      roles: [
        { role: 'Doctor', abbr: 'Doctor' },
        { role: 'Nurse', abbr: 'Nurse' },
        { role: 'Staff', abbr: 'Staff' }
      ],
      rules: {
        required: value => !!value || 'Required.'
      },
      headers: [
        {
          text: 'Name',
          align: 'start',
          sortable: false,
          value: 'displayName'
        },
        { text: 'Email', value: 'email' },
        { text: 'Phone Number', value: 'phoneNumber' },
        { text: 'Role', value: 'role' },
        { text: '', value: 'actions' }
      ],
      invitesHeader: [
        {
          text: 'Email',
          align: 'start',
          sortable: false,
          value: 'email'
        },
        { text: 'Role', value: 'role' },
        { text: '', value: 'actions', sortable: false, align: 'end' }
      ],
      invites: [],
      proxies: [],
      proxiesQuery: [],
      selectedProxy: {
        fname: '',
        lname: '',
        displayName: '',
        email: '',
        phoneNumber: '',
        role: '',
        id: ''
      },
      practiceId: ''
    }
  },
  async mounted() {
    this.currentUser = fb.auth.currentUser.uid
    await fb.proxiesCollection
      .doc(fb.auth.currentUser.uid)
      .get()
      .then(snapshot => {
        const data = snapshot.data()
        this.practiceId = data.practiceId
      })

    this.$bind(
      'proxiesQuery',
      fb.proxiesCollection.where('practiceId', '==', this.practiceId)
    )

    this.$bind(
      'invites',
      fb.db
        .collection(`Invite_Reqs`)
        .where('practiceId', '==', this.practiceId)
        .where('accepted', '==', false)
    )
  },
  watch: {
    proxiesQuery: function() {
      this.proxies = this.proxiesQuery
    },
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
    },
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    }
  },
  methods: {
    resendInvite(item) {
      const resendInviteRequest = fb.functions.httpsCallable(
        'resendInviteRequest'
      )
      try {
        resendInviteRequest({
          email: item.email,
          practice: item.practice,
          req: item.id
        })
      } catch (error) {
        console.log(error)
      }
    },
    deleteInviteSet(item) {
      this.selectedInvite = { ...item, id: item.id }
      this.deleteInviteDialog = true
    },
    async cancelInvite(item) {
      try {
        await fb.db
          .collection('Invite_Reqs')
          .doc(item.id)
          .delete()
        this.deleteInviteDialog = false
      } catch (error) {
        console.log(error)
      }
    },
    editItem(item) {
      this.selectedProxy = { id: item.id, ...item }
      this.dialog = true
    },
    deleteItem(item) {
      this.selectedProxy = { id: item.id, ...item }
      this.dialogDelete = true
    },

    async deleteItemConfirm() {
      await fb.proxiesCollection.doc(this.selectedProxy.id).delete()
      this.closeDelete()
    },

    close() {
      this.dialog = false
    },
    closeDelete() {
      this.dialogDelete = false
    },
    closeAdd() {
      this.addDialog = false
    },

    async updateUser() {
      await fb.proxiesCollection.doc(this.selectedProxy.id).set(
        {
          displayName: this.selectedProxy.displayName,
          fname: this.selectedProxy.fname,
          lname: this.selectedProxy.lname,
          email: this.selectedProxy.email,
          phoneNumber: this.selectedProxy.phoneNumber,
          role: this.selectedProxy.role
        },
        { merge: true }
      )

      this.close()
    }
  }
}
</script>

<style scoped>
.add-patient-dialog {
  padding-left: 20%;
  padding-right: 20%;
  padding-top: 20px;
  padding-bottom: 20px;
}
@media (max-width: 768px) {
  .add-patient-dialog {
    padding: 20px 0;
  }
}
</style>
