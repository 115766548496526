<template>
  <div>
    <v-card class="add-patient-dialog">
      <v-card-title class="d-flex justify-center">
        <span class="headline font-weight-bold">New Exam Component</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="newComponent.name"
                label="Title"
                dense
                outlined
                rounded
                required
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="newComponent.abbrev"
                label="Abbrev"
                dense
                outlined
                rounded
                required
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="newComponent.description"
                label="Description"
                dense
                outlined
                rounded
                required
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-select
                v-model="newComponent.type"
                :items="componentItems"
                item-text="type"
                item-value="type"
                label="Type"
                rounded
                outlined
                dense
                :rules="[rules.required]"
                @change="updateSelectType"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-select
                v-model="recordingSelect"
                :hint="`${recordingSelect} seconds`"
                :items="recordingItems"
                label="Recording Time"
                :rules="[rules.required]"
                persistent-hint
                rounded
                outlined
                dense
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-file-input
                ref="fileInput"
                accept="image/*"
                placeholder="Component Primary Image"
                :label="selectLabel"
                outlined
                rounded
                :rules="[rules.required]"
                required
                :loading="loading"
                dense
                @change="preparePrimaryImage"
              ></v-file-input>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-file-input
                ref="fileInput2"
                accept="image/*"
                placeholder="Component AR Image"
                hint="Choose an Image with a 16:9 Aspect Ratio"
                persistent-hint
                :label="selectLabel"
                outlined
                rounded
                required
                :loading="loading"
                dense
                @change="prepareArImage"
              ></v-file-input>
              <v-checkbox
                v-model="newComponent.arRotation"
                label="Allow AR Image Rotation"
              >
              </v-checkbox>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="4" md="4">
              <div class="black--text font-weight-bold">
                Private Component
              </div>
              <v-switch
                v-model="newComponent.private"
                :label="newComponent.private ? 'on' : 'off'"
                color="success"
                value="private"
                hide-details
              ></v-switch
            ></v-col>
            <v-spacer></v-spacer>
            <v-row>
              <v-col cols="12" sm="4" md="4">
                <v-btn
                  :block="$mq === 'mobile'"
                  @click="setType(0)"
                  color="primary"
                  >3d Female Model</v-btn
                >
              </v-col>

              <v-col cols="12" sm="4" md="4">
                <v-btn
                  :block="$mq === 'mobile'"
                  @click="setType(1)"
                  color="primary"
                  >3d Male Model</v-btn
                >
              </v-col>

              <v-col cols="12" sm="3" md="3">
                <v-btn
                  v-if="buttonType === 1"
                  :block="$mq === 'mobile'"
                  @click="setType(2)"
                  color="primary"
                  >Audio File</v-btn
                >
                <v-btn
                  v-else
                  :block="$mq === 'mobile'"
                  @click="setType(3)"
                  color="primary"
                  >Video File</v-btn
                >
              </v-col>
            </v-row>
          </v-row>
          <v-row v-if="uploadFileDialog">
            <v-col cols="12" sm="12" md="6">
              <v-file-input
                style="margin-top: 32px;"
                accept="image/*, audio/*,video/*"
                placeholder="File"
                :label="selectLabel"
                outlined
                rounded
                :loading="loading"
                dense
                @change="prepareFile"
              ></v-file-input>
            </v-col>
          </v-row>
          <v-row v-if="newComponent.arImage">
            <v-col cols="4" v-if="newComponent.arImage">
              <v-card>
                <v-img
                  :src="newComponent.arImage"
                  max-height="200"
                  contain
                  class="grey darken-4"
                ></v-img>
                <v-card-title class="subtitle-2">
                  AR Image
                </v-card-title>
              </v-card>
            </v-col>
          </v-row>
          <v-row v-if="newComponent.primaryImage">
            <v-col cols="4" v-if="newComponent.primaryImage">
              <v-card>
                <v-img
                  :src="newComponent.primaryImage"
                  max-height="200"
                  contain
                  class="grey darken-4"
                ></v-img>
                <v-card-title class="subtitle-2">
                  Primary Image
                </v-card-title>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions class="d-flex justify-center">
        <v-btn color="primary" @click="addComponent">
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
import * as fb from '../../firebase'
export default {
  name: 'AddExamComponentDialog',
  data() {
    return {
      componentItems: ['Audio', 'Video'],
      newComponent: {
        primaryImage: null,
        private: false,
        name: '',
        description: '',
        abbrev: '',
        type: '',
        creatorId: '',
        recordingTime: 15,
        arRotation: true,
        arImage: null
      },
      primaryImage: null,
      arImage: null,
      type: 0,
      selectLabel: '',
      uploadFileDialog: false,
      loading: false,
      practiceId: '',
      userId: '',
      selectedFile: null,
      rules: {
        required: value => !!value || 'Required.'
      },
      componentId: '',
      buttonType: 0,
      recordingSelect: 15,
      recordingItems: [5, 10, 15, 30, 60, 90, 120]
    }
  },
  async mounted() {
    const currentUser = await fb.proxiesCollection
      .doc(fb.auth.currentUser.uid)
      .get()
    this.userId = currentUser.id

    const practice = await fb.practicesCollection
      .doc(currentUser.data().practiceId)
      .get()
    this.practiceId = practice.id
  },
  methods: {
    updateSelectType() {
      if (this.newComponent.type === 'Video') {
        this.buttonType = 0
      } else {
        this.buttonType = 1
      }
    },
    async addComponent() {
      if (
        this.newComponent.name &&
        this.newComponent.description &&
        this.newComponent.abbrev &&
        this.newComponent.type &&
        this.newComponent.primaryImage
      ) {
        try {
          const component = await fb.examComponentsCollection.doc()
          this.componentId = component.id

          this.uploadFile()

          if (this.newComponent.private) {
            component.set({
              name: this.newComponent.name,
              type: this.newComponent.type,
              description: this.newComponent.description,
              abbrev: this.newComponent.abbrev,
              recordingTime: this.recordingSelect,
              creatorId: this.userId,
              primaryImage: this.newComponent.primaryImage,
              arImage: this.newComponent.arImage
                ? this.newComponent.arImage
                : '',
              arRotation: this.newComponent.arRotation,
              private: true,
              [this.practiceId]: false
            })
          } else {
            component.set({
              name: this.newComponent.name,
              type: this.newComponent.type,
              description: this.newComponent.description,
              abbrev: this.newComponent.abbrev,
              recordingTime: this.recordingSelect,
              creatorId: this.userId,
              primaryImage: this.newComponent.primaryImage,
              arImage: this.newComponent.arImage
                ? this.newComponent.arImage
                : '',
              arRotation: this.newComponent.arRotation,
              [this.userId]: true,
              [this.practiceId]: true,
              private: false
            })
          }
          this.newComponent = {
            private: false,
            name: '',
            description: '',
            abbrev: '',
            type: '',
            recordingTime: 15,
            creatorId: '',
            primaryImage: null,
            arImage: null,
            arRotation: true
          }
        } catch (error) {
          this.error = error.message
          console.log(error)
        }
        this.$emit('closeAddComponent')
      }
    },
    setType(type) {
      this.type = type

      if (this.type === 0) {
        this.selectLabel = '3d Female Model'
      } else if (this.type === 1) {
        this.selectLabel = '3d Male Model'
      } else if (this.type === 2) {
        this.selectLabel = 'Audio File'
      } else {
        this.selectLabel = 'Video File'
      }

      this.uploadFileDialog = true
    },
    prepareArImage(file) {
      if (!file) return
      this.loading = true
      this.arImage = file

      this.uploadArFile()
    },
    preparePrimaryImage(file) {
      if (!file) return
      this.loading = true
      this.primaryImage = file

      this.uploadPrimaryFile()
    },
    prepareFile(file) {
      if (!file) return
      this.loading = true
      this.selectedFile = file
      this.loading = false
    },
    async uploadFile() {
      if (!this.selectedFile) return

      const storageRef = fb.storage.ref()
      const photoRef = storageRef.child(
        `Exam_Components/${this.componentId}/${this.selectedFile.name}`
      )
      const uploadTask = photoRef.put(this.selectedFile)

      uploadTask.on(
        'state_changed',
        snapshot => {
          console.log(snapshot.state)
        },
        error => {
          console.log(error)
        },
        async () => {
          const downloadURL = await uploadTask.snapshot.ref.getDownloadURL()
          fb.examComponentsCollection
            .doc(this.componentId)
            .collection('Files')
            .doc()
            .set(
              {
                url: downloadURL,
                name: this.selectedFile.name,
                type: this.selectedFile.type,
                modelType: this.type
              },
              { merge: true }
            )
        }
      )
      this.uploadFileDialog = true
    },
    async uploadPrimaryFile() {
      if (!this.primaryImage) return

      const storageRef = fb.storage.ref()
      const photoRef = storageRef.child(
        `Exam_Components/Primary_Images/${this.primaryImage.name}`
      )
      const uploadTask = photoRef.put(this.primaryImage)

      uploadTask.on(
        'state_changed',
        snapshot => {
          console.log(snapshot.state)
        },
        error => {
          console.log(error)
        },
        async () => {
          const downloadURL = await uploadTask.snapshot.ref.getDownloadURL()
          this.newComponent.primaryImage = downloadURL
        }
      )
      this.$refs.fileInput.reset()
      // console.log(this.newComponent.primaryImage)
      this.loading = false
    },
    async uploadArFile() {
      if (!this.arImage) return

      const storageRef = fb.storage.ref()
      const photoRef = storageRef.child(
        `Exam_Components/AR_Images/${this.arImage.name}`
      )
      const uploadTask = photoRef.put(this.arImage)

      uploadTask.on(
        'state_changed',
        snapshot => {
          console.log(snapshot.state)
        },
        error => {
          console.log(error)
        },
        async () => {
          const downloadURL = await uploadTask.snapshot.ref.getDownloadURL()
          this.newComponent.arImage = downloadURL
        }
      )
      this.$refs.fileInput2.reset()
      // console.log(this.newComponent.primaryImage)
      this.loading = false
    }
  }
}
</script>
