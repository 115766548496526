<template>
  <div>
    <v-card class="add-patient-dialog pa-4">
      <v-card-title class="d-flex justify-center">
        <span class="headline font-weight-bold">Invite Proxy</span>
      </v-card-title>
      <v-card-text style="max-width: 500px; margin: auto">
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="email"
                label="Email*"
                outlined
                rounded
                dense
                required
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-select
                v-model="role"
                label="Role*"
                :items="roles"
                item-text="abbr"
                item-value="role"
                outlined
                dense
                rounded
                required
                :rules="[rules.required]"
              ></v-select>
            </v-col>
            <v-chip v-if="error" color="error" outlined label class="ml-4">
              {{ errorMsg }}
            </v-chip>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions class="d-flex justify-center">
        <v-btn color="primary" :loading="loading" @click="checkAvailable()">
          Send Invite
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-dialog v-model="dialog" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">
          Invite Sent
        </v-card-title>
        <v-card-actions>
          <v-btn color="green darken-1" text @click="dialog = false">
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import * as fb from '../../firebase'
import { logInviteUser } from '../../Events'
export default {
  props: ['user'],
  data() {
    return {
      dialog: false,
      rules: {
        required: value => !!value || 'Required.'
      },
      loading: false,
      email: '',
      role: '',
      practiceId: '',
      error: false,
      errorMsg: '',
      roles: [
        { role: 'Doctor', abbr: 'Doctor' },
        { role: 'Nurse', abbr: 'Nurse' },
        { role: 'Staff', abbr: 'Staff' }
      ],
      currentUser: {
        practiceId: ''
      },
      thisPractice: {
        id: '',
        owner: '',
        name: ''
      }
    }
  },
  watch: {
    error: function() {
      setTimeout(
        function() {
          this.error = false
        }.bind(this),
        8000
      )
    }
  },
  async mounted() {
    const currentUser = await fb.proxiesCollection
      .doc(fb.auth.currentUser.uid)
      .get()

    this.currentUser.practiceId = currentUser.data().practiceId

    const thisPractice = await fb.practicesCollection
      .doc(this.currentUser.practiceId)
      .get()

    this.thisPractice.id = thisPractice.id
    this.thisPractice.owner = thisPractice.data().owner
    this.thisPractice.name = thisPractice.data().name
  },
  methods: {
    async checkAvailable() {
      var existingUser = []
      var existingInvite = []

      try {
        await fb.proxiesCollection
          .where('email', '==', this.email)
          .get()
          .then(snap => {
            for (const item of snap.docs) {
              existingUser.push(item.id)
            }
          })
      } catch (error) {
        this.errorMsg = error.message
        console.log(error)
      }

      try {
        await fb.db
          .collection('Invite_Reqs')
          .where('email', '==', this.email)
          .get()
          .then(snap => {
            for (const item of snap.docs) {
              existingInvite.push(item.id)
            }
          })
      } catch (error) {
        this.errorMsg = error.message
        console.log(error)
      }

      if (existingUser.length === 0 && existingInvite.length === 0) {
        this.invite()
      } else if (existingUser.length === 0 && existingInvite.length > 0) {
        this.errorMsg = 'There is already a pending invite for this user.'
        this.error = true
      } else {
        this.errorMsg = 'User already exists with this email.'
        this.error = true
      }
    },
    async invite() {
      this.loading = true

      const inviteEmployee = fb.functions.httpsCallable('createInviteRequest')

      try {
        inviteEmployee({
          email: this.email,
          practice: this.thisPractice.name,
          practiceId: this.thisPractice.id,
          role: this.role
        })

        logInviteUser(
          fb.auth.currentUser.uid,
          this.thisPractice.id,
          this.email,
          this.role
        )

        const proxies = await fb.db
          .collection('Proxies')
          .where('practiceId', '==', this.thisPractice.id)
          .get()

        for (const proxy of proxies.docs) {
          fb.db
            .collection('Push_Notifications')
            .doc()
            .set({
              userId: proxy.id,
              date: fb.db.app.firebase_.firestore.Timestamp.fromDate(
                new Date()
              ),
              title: 'New Proxy Invited',
              desc:
                'User with the email: ' +
                this.email +
                ' has been invited to the practice with the role of ' +
                this.role,
              read: false
            })
        }

        this.resetForm()
        this.$emit('closeDialog')
        this.loading = false
        this.dialog = true
      } catch (error) {
        this.loading = false
        this.errorMsg = error.message
        console.log(error)
      }
    },
    resetForm() {
      this.email = null
      this.role = null
    }
  }
}
</script>
