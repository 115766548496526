<template>
  <div :key="component.id">
    <v-card class="add-patient-dialog">
      <v-card-title class="d-flex justify-center">
        <span class="headline font-weight-bold">Edit Exam Component</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="component.name"
                label="Title"
                dense
                outlined
                rounded
                required
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="component.abbrev"
                label="Abbrev"
                dense
                outlined
                rounded
                required
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="component.description"
                label="Description"
                dense
                outlined
                rounded
                required
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-select
                v-model="component.type"
                :items="componentItems"
                item-text="type"
                item-value="type"
                label="Type"
                persistent-hint
                return-object
                rounded
                outlined
                dense
                @change="updateSelectType"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-select
                v-model="recordingSelect"
                :hint="`${recordingSelect} seconds`"
                :items="recordingItems"
                item-text="seconds"
                item-value="secText"
                label="Recording Time"
                :rules="[rules.required]"
                persistent-hint
                rounded
                outlined
                dense
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-file-input
                ref="fileInput"
                accept="image/*"
                placeholder="Component Primary Image"
                outlined
                rounded
                :loading="loading"
                dense
                @change="preparePrimaryImage"
              ></v-file-input>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-file-input
                ref="fileInput2"
                accept="image/*"
                placeholder="Component AR Image"
                hint="Choose an Imgae with a 16:9 Aspect Ratio"
                persistent-hint
                outlined
                rounded
                :loading="loading"
                dense
                @change="prepareArImage"
              ></v-file-input>
              <v-checkbox
                v-model="component.arRotation"
                label="Allow AR Image Rotation"
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="4" md="4">
              <div class="black--text font-weight-bold">
                Private Component
              </div>
              <v-switch
                v-model="component.private"
                :label="component.private ? 'on' : 'off'"
                color="success"
                :value="component.private"
                hide-details
              ></v-switch
            ></v-col>
            <v-spacer></v-spacer>
            <v-row>
              <v-col cols="12" sm="4" md="4">
                <v-btn
                  :block="$mq === 'mobile'"
                  @click="setType(0)"
                  color="primary"
                  >3d Female Model</v-btn
                >
              </v-col>

              <v-col cols="12" sm="4" md="4">
                <v-btn
                  :block="$mq === 'mobile'"
                  @click="setType(1)"
                  color="primary"
                  >3d Male Model</v-btn
                >
              </v-col>

              <v-col cols="12" sm="3" md="3">
                <v-btn
                  v-if="buttonType === 1"
                  :block="$mq === 'mobile'"
                  @click="setType(2)"
                  color="primary"
                  >Audio File</v-btn
                >
                <v-btn
                  v-else
                  :block="$mq === 'mobile'"
                  @click="setType(3)"
                  color="primary"
                  >Video File</v-btn
                >
              </v-col>
            </v-row>
          </v-row>
          <v-row v-if="uploadFileDialog">
            <v-col cols="12" sm="12" md="6">
              <v-file-input
                style="margin-top: 32px;"
                accept="image/*, audio/*,video/*"
                placeholder="File"
                :label="selectLabel"
                outlined
                rounded
                :loading="loading"
                dense
                @change="prepareFile"
              ></v-file-input>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4" v-if="component.primaryImage">
              <v-card>
                <v-img
                  :src="component.primaryImage"
                  max-height="200"
                  contain
                  class="grey darken-4"
                ></v-img>
                <v-card-title class="subtitle-2">
                  Primary Image
                </v-card-title>
              </v-card>
            </v-col>
            <v-col cols="4" v-if="component.arImage">
              <v-card>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn icon color="red" @click="removeArImage()">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-card-actions>
                <v-img
                  :src="component.arImage"
                  max-height="200"
                  contain
                  class="grey darken-4"
                ></v-img>
                <v-card-title class="subtitle-2">
                  AR Image
                </v-card-title>
              </v-card>
            </v-col>
            <v-col cols="4" v-for="file in files" :key="file.id">
              <v-card
                v-if="file.type === 'image/png' || file.type === 'image/jpeg'"
              >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn icon color="red" @click="deleteItem(file.id)">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-card-actions>
                <v-img
                  :src="file.url"
                  max-height="200"
                  contain
                  class="grey darken-4"
                ></v-img>
                <v-card-title class="subtitle-2">
                  {{ file.name }}
                </v-card-title>
              </v-card>
              <v-card
                v-else-if="
                  file.type === 'audio/wav' || file.type === 'audio/mpeg'
                "
                class="pr-4 pb-4 pl-4"
              >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn icon color="red" @click="deleteItem(file.id)">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-card-actions>
                <UniversalAudioPlayer
                  v-if="file.url"
                  :selectedFile="file.url"
                />
                <v-card-title class="subtitle-2">
                  {{ file.name }}
                </v-card-title>
              </v-card>
              <v-card
                v-else-if="
                  file.type === 'video/webm' || file.type === 'video/mp4'
                "
                class="pr-4 pb-4 pl-4"
              >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn icon color="red" @click="deleteItem(file.id)">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-card-actions>
                <div v-if="file.url">
                  <VuePlyr style="max-width: 100%">
                    <video style="max-width: 100%; max-height: 720px;" controls>
                      <source :src="file.url" type="video/mp4" />
                    </video>
                  </VuePlyr>
                </div>
                <v-card-title class="subtitle-2">
                  {{ file.name }}
                </v-card-title>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions class="d-flex justify-center">
        <v-btn color="primary" @click="updateComponent">
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
import * as fb from '../../firebase'
import UniversalAudioPlayer from '@/components/UniversalAudioPlayer'
export default {
  name: 'EditExamComponentDialog',
  props: ['editItemId'],
  components: {
    UniversalAudioPlayer
  },
  data() {
    return {
      componentItems: ['Audio', 'Video'],
      component: {},
      type: 0,
      selectLabel: '',
      uploadFileDialog: false,
      loading: false,
      practiceId: '',
      userId: '',
      selectedFile: null,
      recordingSelect: 15,
      recordingItems: [5, 10, 15, 30, 60, 90, 120],
      rules: {
        required: value => !!value || 'Required.'
      },
      files: [],
      componentId: '',
      buttonType: 0,
      primaryImage: null,
      arImage: null
    }
  },
  watch: {
    editItemId: {
      immediate: true,
      async handler() {
        const component = await fb.examComponentsCollection
          .doc(this.editItemId)
          .get()

        this.component = Object.assign({
          id: component.id,
          ...component.data()
        })
        this.recordingSelect = component.data().recordingTime
      }
    }
  },
  async mounted() {
    const currentUser = await fb.proxiesCollection
      .doc(fb.auth.currentUser.uid)
      .get()
    this.userId = currentUser.id

    const practice = await fb.practicesCollection
      .doc(currentUser.data().practiceId)
      .get()
    this.practiceId = practice.id

    // const component = await fb.examComponentsCollection
    //   .doc(this.editItemId)
    //   .get()
    this.$bind('component', fb.examComponentsCollection.doc(this.editItemId))
    this.$bind(
      'files',
      fb.examComponentsCollection.doc(this.component.id).collection('Files')
    )

    // this.component = Object.assign({
    //   id: component.id,
    //   ...component.data()
    // })

    // this.updateItems
  },
  methods: {
    updateSelectType() {
      if (this.component.type === 'Video') {
        this.buttonType = 0
      } else {
        this.buttonType = 1
      }
    },
    async updateComponent() {
      if (
        this.component.name &&
        this.component.description &&
        this.component.abbrev
      ) {
        try {
          fb.db
            .collection('Default_Exam')
            .where('id', '==', this.editItemId)
            .get()
            .then(snapshot => {
              var batch = fb.db.batch()
              if (this.component.private) {
                snapshot.forEach(doc => {
                  batch.update(doc.ref, {
                    name: this.component.name,
                    type: this.component.type,
                    description: this.component.description,
                    abbrev: this.component.abbrev,
                    recordingTime: this.recordingSelect,
                    creatorId: this.userId,
                    private: true,
                    [this.practiceId]: false
                  })
                })
              } else {
                snapshot.forEach(doc => {
                  batch.update(doc.ref, {
                    name: this.component.name,
                    type: this.component.type,
                    description: this.component.description,
                    abbrev: this.component.abbrev,
                    recordingTime: this.recordingSelect,
                    creatorId: this.userId,
                    [this.userId]: true,
                    [this.practiceId]: true,
                    private: false
                  })
                })
              }
              batch.commit()
            })

          const component = await fb.examComponentsCollection.doc(
            this.editItemId
          )
          this.componentId = this.editItemId

          this.uploadFile()

          if (this.component.private) {
            component.set(
              {
                name: this.component.name,
                type: this.component.type,
                description: this.component.description,
                abbrev: this.component.abbrev,
                recordingTime: this.recordingSelect,
                creatorId: this.userId,
                private: true,
                [this.practiceId]: false
              },
              { merge: true }
            )
          } else {
            component.set(
              {
                name: this.component.name,
                type: this.component.type,
                description: this.component.description,
                abbrev: this.component.abbrev,
                recordingTime: this.recordingSelect,
                creatorId: this.userId,
                [this.userId]: true,
                [this.practiceId]: true,
                private: false
              },
              { merge: true }
            )
          }
        } catch (error) {
          this.error = error.message
          console.log(error)
        }
        this.uploadFileDialog = false
        this.$emit('closeEdit')
      } else {
        //
      }
    },
    async removeArImage() {
      const component = await fb.examComponentsCollection.doc(this.editItemId)
      component.update({
        arImage: ''
      })
    },
    setType(type) {
      this.type = type

      if (this.type === 0) {
        this.selectLabel = '3d Female Model'
      } else if (this.type === 1) {
        this.selectLabel = '3d Male Model'
      } else if (this.type === 2) {
        this.selectLabel = 'Audio File'
      } else {
        this.selectLabel = 'Video File'
      }

      this.uploadFileDialog = true
    },
    prepareArImage(file) {
      if (!file) return
      this.loading = true
      this.arImage = file

      this.uploadArFile()
    },
    preparePrimaryImage(file) {
      if (!file) return
      this.loading = true
      this.primaryImage = file

      this.uploadPrimaryFile()
    },
    prepareFile(file) {
      if (!file) return
      this.loading = true
      this.selectedFile = file
      this.loading = false
    },
    async uploadFile() {
      if (!this.selectedFile) return

      const storageRef = fb.storage.ref()
      const photoRef = storageRef.child(
        `Exam_Components/${this.componentId}/${this.selectedFile.name}`
      )
      const uploadTask = photoRef.put(this.selectedFile)

      uploadTask.on(
        'state_changed',
        snapshot => {
          console.log(snapshot.state)
        },
        error => {
          console.log(error)
        },
        async () => {
          const downloadURL = await uploadTask.snapshot.ref.getDownloadURL()
          fb.examComponentsCollection
            .doc(this.componentId)
            .collection('Files')
            .doc()
            .set(
              {
                url: downloadURL,
                name: this.selectedFile.name,
                type: this.selectedFile.type,
                modelType: this.type
              },
              { merge: true }
            )
        }
      )
      this.uploadFileDialog = true
    },
    async uploadArFile() {
      if (!this.arImage) return

      const storageRef = fb.storage.ref()
      const photoRef = storageRef.child(
        `Exam_Components/AR_Images/${this.arImage.name}`
      )
      const uploadTask = photoRef.put(this.arImage)

      uploadTask.on(
        'state_changed',
        snapshot => {
          console.log(snapshot.state)
        },
        error => {
          console.log(error)
        },
        async () => {
          const downloadURL = await uploadTask.snapshot.ref.getDownloadURL()
          fb.examComponentsCollection.doc(this.component.id).set(
            {
              arImage: downloadURL
            },
            { merge: true }
          )

          fb.db
            .collection('Default_Exam')
            .where('id', '==', this.component.id)
            .get()
            .then(snapshot => {
              var batch = fb.db.batch()
              snapshot.forEach(doc => {
                batch.update(doc.ref, {
                  arImage: downloadURL
                })
              })
              batch.commit()
            })
        }
      )
      this.$refs.fileInput2.reset()
      this.loading = false
    },
    async uploadPrimaryFile() {
      if (!this.primaryImage) return

      const storageRef = fb.storage.ref()
      const photoRef = storageRef.child(
        `Exam_Components/Primary_Images/${this.primaryImage.name}`
      )
      const uploadTask = photoRef.put(this.primaryImage)

      uploadTask.on(
        'state_changed',
        snapshot => {
          console.log(snapshot.state)
        },
        error => {
          console.log(error)
        },
        async () => {
          const downloadURL = await uploadTask.snapshot.ref.getDownloadURL()
          fb.examComponentsCollection.doc(this.component.id).set(
            {
              primaryImage: downloadURL
            },
            { merge: true }
          )

          fb.db
            .collection('Default_Exam')
            .where('id', '==', this.component.id)
            .get()
            .then(snapshot => {
              var batch = fb.db.batch()
              snapshot.forEach(doc => {
                batch.update(doc.ref, {
                  primaryImage: downloadURL
                })
              })
              batch.commit()
            })
        }
      )
      this.$refs.fileInput.reset()
      this.loading = false
    },
    deleteItem(item) {
      try {
        fb.examComponentsCollection
          .doc(this.component.id)
          .collection('Files')
          .doc(item)
          .delete()

        // this.updateItems()
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>
<style>
audio {
  width: 100%;
}
canvas {
  width: 100%;
  height: 100% !important;
}
</style>
