<template>
  <div>
    <v-card class="mt-4 pa-4" rounded="lg">
      <v-card-text>
        <v-row>
          <v-col cols="12" md="6">
            <v-card-text class="title black--text pl-0 pt-0"
              >Practice Address</v-card-text
            >
            <v-text-field
              v-model="practice.street"
              label="Street"
              required
              outlined
              dense
              rounded
            ></v-text-field>
            <v-text-field
              v-model="practice.city"
              label="City"
              required
              outlined
              dense
              rounded
            ></v-text-field>
            <v-text-field
              v-model="practice.state"
              label="State"
              required
              outlined
              dense
              rounded
            ></v-text-field>
            <v-text-field
              v-model="practice.zip"
              label="Zip"
              required
              outlined
              dense
              rounded
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-card-text class="title black--text pl-0 pt-0"
              >Practice Name</v-card-text
            >
            <v-text-field
              v-model="practice.name"
              label="Name"
              required
              outlined
              dense
              rounded
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="ma-2">
        <v-btn
          color="primary"
          :block="$mq === 'mobile'"
          @click="save"
          :loading="loading"
          >Save</v-btn
        >
        <v-chip v-if="indicate" color="grey" outlined label class="ml-4">
          <v-icon left>
            mdi-checkbox-marked-outline
          </v-icon>
          Updated
        </v-chip>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import * as fb from '../../firebase'
import * as event from '../../Events'
export default {
  name: 'Practice',

  data() {
    return {
      indicate: false,
      loading: false,
      practice: {
        street: '1234 Some St.',
        city: 'Cleveland',
        state: 'Ohio',
        zip: '44130',
        name: 'My Practice',
        id: ''
      }
    }
  },
  watch: {
    indicate: function() {
      setTimeout(
        function() {
          this.indicate = false
        }.bind(this),
        2000
      )
    }
  },
  async mounted() {
    const currentUser = await fb.proxiesCollection
      .doc(fb.auth.currentUser.uid)
      .get()

    const practice = await fb.practicesCollection
      .doc(currentUser.data().practiceId)
      .get()

    this.practice = Object.assign({
      id: practice.id,
      ...practice.data()
    })
  },

  methods: {
    async save() {
      this.loading = true
      this.updatePractice()
      // this.updateProxies()
      this.loading = false
    },
    async updatePractice() {
      try {
        await fb.practicesCollection.doc(this.practice.id).set(
          {
            street: this.practice.street,
            city: this.practice.city,
            state: this.practice.state,
            zip: this.practice.zip,
            name: this.practice.name
          },
          { merge: true }
        )
        this.indicate = true
        event.logUpdatePractice(fb.auth.currentUser.uid, this.practice.id)
      } catch (error) {
        this.loading = false
        this.error = error.message
      }
    },
    async updateProxies() {
      try {
        // update practice name on all proxies in the practice
        await fb.proxiesCollection
          .where('practiceId', '==', this.practice.id)
          .get()
          .then(function(querySnapshot) {
            querySnapshot.forEach(proxyDoc => {
              // console.log(proxyDoc.data().practice)
              proxyDoc.ref.set(
                {
                  practice: this.practice.name
                },
                { merge: true }
              )
            })
          })
      } catch (error) {
        this.loading = false
        this.error = error.message
        console.log(error)
      }
    }
  }
}
</script>
