<template>
  <div>
    <vue-wave-surfer
      v-if="selectedFile"
      :src="selectedFile"
      :options="options"
      ref="waveform"
    ></vue-wave-surfer>
    <v-btn @click="playAudio" v-if="!isPlaying" icon color="red" dark>
      <v-icon>mdi-play-circle</v-icon>
    </v-btn>
    <v-btn @click="pauseAudio" v-if="isPlaying" icon color="red" dark>
      <v-icon>mdi-pause-circle</v-icon>
    </v-btn>
  </div>
</template>
<script>
export default {
  name: 'UniversalAudioPlayer',
  props: ['selectedFile'],
  data() {
    return {
      isPlaying: false,
      file: '',
      options: { waveColor: 'red' }
    }
  },
  mounted() {
    this.player.on('ready', () => {
      this.isPlaying = false
      // this.player.play()
    })
    this.player.on('finish', () => {
      this.isPlaying = false
      this.stopAudio()
    })
  },
  methods: {
    playAudio() {
      const player = this.$refs.waveform.waveSurfer
      this.isPlaying = true
      player.play()
    },
    pauseAudio() {
      const player = this.$refs.waveform.waveSurfer
      this.isPlaying = false
      player.pause()
    },
    stopAudio() {
      this.player.stop()
      this.isPlaying = false
    }
  },
  computed: {
    player() {
      return this.$refs.waveform.waveSurfer
    }
  }
}
</script>
